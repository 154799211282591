import React, { useEffect, useState } from 'react'
import { Button, Col, Form, Row, Spinner, Table, Toast, ToastContainer } from 'react-bootstrap'
import Select from 'react-select'
import { useNavigate, useParams } from 'react-router-dom'
import { KTSVG } from '../../../_metronic/helpers'
import * as Yup from 'yup'
import {
  createOfferNew,
  editOfferNew,
  getBuyerSeller,
  getOfferByIdNew,
} from '../offer-management/offers-list/core/_requests'
import {
  getAllProduct,
  getCGST,
  getIGST,
  getLocation,
  getSGST,
} from '../inquiry-management/Inquiry-list/core/_requests'
import { useFormik, ErrorMessage } from 'formik'
import {
  createQuotationNew,
  getAllInquiryList,
  getAllStateList,
  getQuotationByIdNew,
  getTranspotationList,
  updateQuotationNew,
  getDistrictLocation,
  getAllInquiryListSearch,
  getDistrictLocationAll,
  getDistrictLocationByID
} from './quotatuins-list/core/_requests'
import moment from 'moment'
import { createPurchaseNew, updatePurchaseNew } from '../purchase-order/purchases-list/core/_requests'
import toast, { Toaster } from 'react-hot-toast'
import { getDistrictListByStateID } from '../masters/transports/transports-list/core/_requests'
import { useDropzone } from 'react-dropzone'
import {
  uploadFile,
} from '../purchase-order/purchases-list/core/_requests'

const phoneRegExp =
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/

const loginSchema = Yup.object().shape({
  buyer_id: Yup.string().required('This Field is required'),
  destination_id: Yup.string().required('This Field is required'),
  date: Yup.string().required('This Field is required'),
  contact_person_name: Yup.string().required('This Field is required'),
  shipping_address: Yup.object().shape({
    address_line_1: Yup.string().required('This Field is required'),
    district: Yup.string().required('This Field is required'),
    city: Yup.string().required('This Field is required'),
    pincode: Yup.number()
      .positive('Please Enter valid pin')
      .integer('Please Enter valid pin')
      .required('This Field is required'),
  }),
  firm_person_id: Yup.string().required('This Field is required'),
  whatsapp_number: Yup.string()
    .required('This Field is required')
    .max(12, 'must be less than 12 characters')
    .min(10, 'must be at least 10 characters')
    .matches(phoneRegExp, 'Whatsapp number is not valid'),
  transport_id: Yup.string().required('This Field is required'),
  payment_terms_note: Yup.string().when('payment_terms', {
    is: (payment_terms: any) =>  payment_terms == true as any,
    then: Yup.string().required('This Field is required').max(50, 'Only 50 Characters Allowed')
  })  
  // inquiry_id: Yup.string().required('This Field is required'),
  // buyer_district_id: Yup.string().required('This Field is required'),
  // buyer_id: Yup.string().required('This Field is required')
  // email: Yup.string().required('This Field is required').email('This email is not valid'),
  // state_id: Yup.string().required('This Field is required'),
  // quotation_details:Yup.array(Yup.object({
  //     product_id: Yup.string().required("This Field is required"),
  // })).required("This Field is required required"),
  // product_id: Yup.string().required('product id id is required'),
  // quantity: Yup.number().required('quantity id is required').positive('Must be a positive number.'),
  // price: Yup.number().required('price id is required').positive('Must be a positive number.'),
  // validity: Yup.number().required('validity id is required').positive('Must be a positive number.'),
})

const initialValues = {
  destination_id: '',
  firm_person_id: '',
  whatsapp_number: '',
  date: '',
  reference: '',
  inquiry_id: '',
  buyer_id: '',
  contact_person_name: '',
  transport_id: '',
  email: '',
  state_id: '',
  buyer_billing_address_id: '',
  buyer_shipping_address_id: '',
  shipping_address: {
    address_line_1: '',
    address_line_2: '',
    city: '',
    state: '',
    district: '',
    state_id: '',
    district_id: '',
    country: 'India',
    pincode: '',
  },
  quotation_details: [
    {
      product_id: '',
      quantity: '',
      rate: '',
    },
  ],
  total_amount: 0,
  payment_terms: 0,
  payment_terms_note:''
}

const AddEditQuotation = () => {
  const navigate = useNavigate()
  const navigateViewQuotation = () => {
    // 👇️ navigate to /
    navigate(`/viewquotation/${id}`)
  }
  const navigateQuotationListing = () => {
    // 👇️ navigate to /
    navigate('/quotation-management/quotations')
  }
  const [isShowSourceFild, setIsShowSourceFild] = useState(false)

  const params = useParams()
  const id: any = params.id ? params.id : ''
  const [loadingList, setLoadingList] = useState(false)
  const [loadingGetData, setLoadingGetData] = useState(false)
  const [loading, setLoading] = useState(false)
  const [lstFirm, setLstFirm] = useState([])
  const [lstBuyyerSeller, setLstBuyyerSeller] = useState([])
  const [billingAddressList, setBillingAddressList] = useState([])
  const [shippingAddressList, setShippingAddressList] = useState([])
  const [lstLocation, setLstLocation] = useState([])
  const [lstLocationShipping, setLstLocationShipping] = useState([])
  const [lstLocationState, setLstLocationState] = useState([])
  const [lstTransport, setLstTransport] = useState([])
  const [lstProduct, setLstProduct] = useState([])
  const [inquiryId, setInquiryId] = useState([])
  const [masterInquiryId, setMasterInquiryId] = useState([])

  const [selectedMasterFirm, setSelectedMasterFirm] = useState<any>({})
  const [lstFirmMaster, setLstFirmMaster] = useState([])
  const [personList, setPersonList] = useState<any>([])
  // const [lstLocation2, setLstLocation2] = useState([])
  const [valueSGST, setValueSGST] = useState(0)
  const [valueIGST, setValueIGST] = useState(0)
  const [valueCGST, setValueCGST] = useState(0)
  const [lstMasterProduct, setLstMasterProduct] = useState([])
  const [district, setDistrict] = useState<any>(null)
  const [districtShipping, setDistrictShipping] = useState<any>(null)
  const [searchForInquiry, setSearchForInquiry] = useState<any>('')
  const [stateBuyer, setStateBuyer] = useState<any>('')
  const [oldDataById, setOldDataById] = useState<any>({})
  const [lstProductDetails, setLstProductDetails] = useState([
    {
      product_id: '',
      quantity: '',
      rate: '',
    },
  ])
  const [dataInquiryListing, setDataInquiryListing] = useState([])

  const [sessionMessage, setSessionMessage] = useState({
    type: '',
    message: '',
    show: false,
  })

  useEffect(() => {
    getFirmList()
    if (!id) {
      formik.setFieldValue('date', moment(new Date()).format('YYYY-MM-DD'))
    }
  }, [])
  useEffect(() => {
    getInquiry()
  }, [searchForInquiry])

  useEffect(() => {
    if (id) {
      getByID(id)
    }
  }, [id, lstFirmMaster])

  useEffect(() => {
    setDistrictShippingList()
  }, [districtShipping, id])

  // Edit
  const getByID = async (id: string) => {
    try{
      setLoadingGetData(true)
      let data: any = await getQuotationByIdNew(id)
      if (data?.responseData) {
        setOldDataById(data?.responseData ?? {})
        formik.setFieldValue('inquiry_id', data?.responseData?.inquiry_id ?? '')
        formik.setFieldValue('transport_id', data?.responseData?.transport_id ?? '')
        formik.setFieldValue('buyer_id', data?.responseData?.buyer_id ?? '')
        setBuyerAddress(
          lstFirm.find((x: any) => x.value == data?.responseData?.buyer_id),
          'edit',
          data?.responseData
        )
        formik.setFieldValue('firm_person_id', data?.responseData?.firmBuyerInfo?.id ?? '')
        formik.setFieldValue(
          'whatsapp_number',
          data?.responseData?.firmBuyerInfo?.whatsapp_number ?? ''
        )
        formik.setFieldValue('email', data?.responseData?.firmBuyerInfo?.email ?? '')
        formik.setFieldValue('reference', data?.responseData?.reference ?? '')
        formik.setFieldValue('contact_person_name', data?.responseData?.contact_person_name ?? '')

        formik.setFieldValue('date', data?.responseData?.date ?? '')
        formik.setFieldValue(
          'state_id',
          data?.responseData?.billingAddressLocationInfo?.addressLocationInfo?.state_id ?? ""
        )
        if(data?.responseData?.shippingAddressLocationInfo){
          formik.setFieldValue('shipping_address.address_line_1',data?.responseData?.shippingAddressLocationInfo?.address_line_1 ?? "")
          formik.setFieldValue('shipping_address.address_line_1',data?.responseData?.shippingAddressLocationInfo?.address_line_1 ?? "")
          formik.setFieldValue('shipping_address.address_line_2',data?.responseData?.shippingAddressLocationInfo?.address_line_2 ?? "")
          formik.setFieldValue('shipping_address.district_id',data?.responseData?.shippingAddressLocationInfo?.district_id ?? "")
          formik.setFieldValue('shipping_address.district',data?.responseData?.shippingAddressLocationInfo?.addressLocationInfo?.name ?? "")
          formik.setFieldValue('shipping_address.state_id',data?.responseData?.shippingAddressLocationInfo?.state_id ?? "")
          formik.setFieldValue('shipping_address.country',data?.responseData?.shippingAddressLocationInfo?.country ?? "India")
          formik.setFieldValue('shipping_address.pincode',data?.responseData?.shippingAddressLocationInfo?.pincode ?? "")
          formik.setFieldValue('shipping_address.state',data?.responseData?.shippingAddressLocationInfo?.addressLocationInfo?.stateInfo?.name ?? "")
          formik.setFieldValue('shipping_address.city',data?.responseData?.shippingAddressLocationInfo?.city ?? "")
        }
        formik.setFieldValue('buyer_billing_address_id', data?.responseData?.buyer_billing_address_id ?? "")
        formik.setFieldValue('buyer_shipping_address_id', data?.responseData?.buyer_shipping_address_id ?? "")
        setStateBuyer(data?.responseData?.transportInfo.state_id)
        formik.setFieldValue('destination_id', data?.responseData?.destination_id)
        formik.setFieldValue('payment_terms', data?.responseData?.payment_terms ?? '')
      formik.setFieldValue('payment_terms_note', data?.responseData?.payment_terms_note ?? '')
      formik.setFieldValue('payment_terms_attachment', data?.responseData?.payment_terms_attachment ?? '')
        setLstProductDetails(data?.responseData?.quotationDetailsInfo)
        setLoadingGetData(false)
      }
    }
    catch(err){
      console.error(err)
    }
  }

  const getInquiry = async () => {
    let dataInquiry: any = await getAllInquiryListSearch(searchForInquiry, !id ? true : false)
    console.log(dataInquiry, 'dataInquiry call')
    setDataInquiryListing(dataInquiry.data)
    let lstTmpInquiry = dataInquiry.data.map((obj: any) => {
      return {value: obj.id, label: obj.inquiry_number}
    })
    setInquiryId(lstTmpInquiry)
    setMasterInquiryId(dataInquiry.data)
  }

  const getDataOfBuyerSellerList = async () => {
    try{
      let dataFirm: any = await getBuyerSeller()
      let lstTmpFirm = dataFirm.responseData.map((obj: any) => {
        return { ...obj, value: obj.id, label: obj.firm_name }
      })
      let lstTmpBuyerSeller = dataFirm.responseData.map((obj: any) => {
        return { value: obj.id, label: obj.name }
      })
      setLstFirmMaster(dataFirm.responseData)
      setLstFirm(lstTmpFirm)
      setLstBuyyerSeller(lstTmpBuyerSeller)
    }
    catch(err){
      console.log(err)
    }
  }

  const getDataOfTranspotationList = async () => {
    try{
      let dataTranspotation: any = await getTranspotationList()
      let lstTmpTranspotation = dataTranspotation.data.map((obj: any) => {
        return { value: obj.id, label: obj.firm_name }
      })
      setLstTransport(lstTmpTranspotation)
       const defaultTransporter = lstTmpTranspotation.find(
          (transporter: any) => transporter.label.toLowerCase() === "to be assigned"
        );
        if (defaultTransporter) {
          formik.setFieldValue('transport_id', defaultTransporter.value);
        }
    }
    catch(err){
      console.log(err)
    }
  }

  const getDataOfStateList = async () => {
    try{
      // State
      let dataLocationState: any = await getAllStateList()
      let lstTmpLocationState = dataLocationState.responseData.map((obj: any) => {
        return { value: obj.id, label: obj.name }
      })
      setLstLocationState(lstTmpLocationState)
    }
    catch(err){
      console.log(err)
    }
  }

  const getDataOfProductList = async () => {
    try{
      // Product
      let dataProduct: any = await getAllProduct()
      let lstTmpProduct = dataProduct.responseData.map((obj: any) => {
        return { value: obj.id, label: obj.product_variation }
      })
      setLstProduct(lstTmpProduct)
      setLstMasterProduct(dataProduct.responseData)
    }
    catch(err){
      console.log(err)
    }
  }

  const getDataOfGST = async() => {
    try{
      // SGST
      let SGST: any = await getSGST()

      let tmpSGST = SGST.responseData.value
      setValueSGST(tmpSGST)

      // IGST
      let IGST: any = await getIGST()
      let tmpIGST = IGST.responseData.value
      setValueIGST(tmpIGST)

      // CGST
      let CGST: any = await getCGST()
      let tmpCGST = CGST.responseData.value
      setValueCGST(tmpCGST)
    }
    catch(err){
      console.log(err)
    }
  }

  // Firm
  const getFirmList = async () => {
    setLoadingList(true)
    await Promise.all([
      getDataOfBuyerSellerList(),
      getDataOfTranspotationList(),
      getDataOfStateList(),
      getDataOfProductList(),
      getDataOfGST()
    ])
    setLoadingList(false)
  }

  const selectedValueInquiry = (id: string) => {
    let selected = inquiryId && inquiryId.length && inquiryId.filter((obj: any) => obj.value == id)
    if (selected && selected.length) {
      return selected[0]
    } else {
      return []
    }
  }

  const selectedValueFirm = (id: string) => {
    let selected = lstFirm && lstFirm.length && lstFirm.filter((obj: any) => obj.value == id)
    if (selected && selected.length) {
      return selected[0]
    } else {
      return []
    }
  }

  // Seller
  const selectedValueSeller = (id: string) => {
    let selected = personList.filter((obj: any) => obj.value == id)
    if (selected.length) {
      return selected[0]
    } else {
      return []
    }
  }

  // Transport
  const selectedTransportList = (id: string) => {
    let selected = lstTransport.filter((obj: any) => obj.value == id)
    if (selected.length) {
      return selected[0]
    } else {
      return []
    }
  }

  // State
  const selectedValueState = (id: string) => {
    let selected = lstLocationState.filter((obj: any) => obj.value == id)
    if (selected.length) {
      return selected[0]
    } else {
      return []
    }
  }

  // Product
  const selectedValueProduct = (id: string) => {
    let selected = lstProduct.filter((obj: any) => obj.value == id)
    if (selected.length) {
      return selected[0]
    } else {
      return []
    }
  }

  // Billing Location
  const selectedValueBillingLocation = (id: string) => {
    let selected = billingAddressList.filter((obj: any) => obj.value == id)
    if (selected.length) {
      return selected[0]
    } else {
      return []
    }
  }

  // Shipping Location
  const selectedValueShippingLocation = (id: string) => {
    let selected = shippingAddressList.filter((obj: any) => obj.value == id)
    if (selected.length) {
      return selected[0]
    } else {
      return []
    }
  }

  useEffect(() => {
    if (stateBuyer) setDistrictList()
  }, [stateBuyer, id])

  useEffect(() => {
    if (districtShipping) setDistrictShippingList()
  }, [districtShipping, id])

  // Get District
  const setDistrictList = async () => {
    let dataLocation: any = await getDistrictListByStateID(stateBuyer)
    let lstTmpLocation = dataLocation.data.map((obj: any) => {
      return { value: obj.id, label: obj.name }
    })
    setLstLocation(lstTmpLocation)
  }

  const setDistrictShippingList = async () => {
    let dataLocation: any = await getDistrictListByStateID(districtShipping)
    let lstTmpLocation = dataLocation.data.map((obj: any) => {
      return { value: obj.id, label: obj.name }
    })
    console.log(lstTmpLocation, 'lstTmpLocation')

    // setLstLocation(lstTmpLocation)
    setLstLocationShipping(lstTmpLocation)
  }

  // get Shipping District

  const successToast = () => toast.success('Here is your toast.')

  const formik = useFormik({
    initialValues,
    validationSchema: loginSchema,
    onSubmit: async (values, { setStatus, setSubmitting }) => {
      setLoading(true)
      console.log('values :: ', values);

      let objFinal
      try {
        // let objFinal = {
        //   ...values,
        //   tax: getSGSTValue() + getCGSTValue() + getIGSTValue(),
        //   cgst: getCGSTValue(),
        //   igst: getIGSTValue(),
        //   sgst: getSGSTValue(),
        //   quotation_details: lstProductDetails.map((obj: any) => {
        //     return {...obj, amount: parseFloat(obj.quantity) * parseFloat(obj.rate)}
        //   }),
        // }
        let total = 0
        console.log(lstProductDetails, '4800000')

        lstProductDetails.map((obj: any) => {
          return (total = total + parseFloat(obj.quantity) * parseFloat(obj.rate)) * 1000
        })

        if (formik.values.state_id !== '3749b049-6434-4e8b-8358-478251a33f8d') {
          objFinal = {
            ...values,
            tax: getIGSTValue(),
            igst: getIGSTValue(),
            total_amount: getTotalAmoutWithTex(),
            quotation_details: lstProductDetails.map((obj: any) => {
              return { ...obj, amount: parseFloat(obj.quantity) * parseFloat(obj.rate) }
            }),
          }
        } else {
          objFinal = {
            ...values,
            tax: getSGSTValue() + getCGSTValue(),
            cgst: getCGSTValue(),
            sgst: getSGSTValue(),
            total_amount: getTotalAmoutWithTex(),
            quotation_details: lstProductDetails.map((obj: any) => {
              return { ...obj, amount: parseFloat(obj.quantity) * parseFloat(obj.rate) }
            }),
          }
        }
        if (id) {
          let dataNew: any = await updateQuotationNew(id, objFinal)
          console.log(dataNew, 'thay gyu ho suc')
          if (dataNew.responseStatus == 200) {
            navigate('/quotation-management/quotations', {
              state: { type: 'success', message: dataNew.responseMessage, show: true },
            })
            setLoading(false)
          } else {
            setSessionMessage({ type: 'danger', message: dataNew.responseMessage, show: true })
            setLoading(false)
          }
        } else {
          const dataNew: any = await createQuotationNew(objFinal)
          if (dataNew.responseStatus == 200) {
            setSessionMessage({ type: 'success', message: dataNew.responseMessage, show: true })
            navigate('/quotation-management/quotations')
            setLoading(false)
          } else {
            setSessionMessage({ type: 'danger', message: dataNew.responseMessage, show: true })
            setLoading(false)
          }
        }
      } catch (error) {
        console.error(error)
        setStatus('The login details are incorrect')
        setSubmitting(false)
        setLoading(false)
      }
    },
  }) as any
  

  console.log(formik, 'formik.errors')

  useEffect(() => {
    formik.validateForm()
  }, [])

  // Get master object
  const getMasterProductObject = (id: any) => {
    let selected = lstMasterProduct.filter((obj: any) => obj.id == id)
    if (selected.length) {
      return selected[0]
    } else {
      return {}
    }
  }

  const getMasterInqObject = (id: string) => {
    let selected = masterInquiryId.filter((obj: any) => obj.id == id)
    if (selected.length) {
      return selected[0]
    } else {
      return {}
    }
  }
  const onChangeProductDetail = (index: number, key: string, newVal: any) => {
    let lstTmp: any = [...lstProductDetails]
    if (key == 'product_id') {
      let objectMain = getMasterProductObject(newVal) as any
      lstTmp[index] = { ...lstTmp[index], [key]: newVal, rate: objectMain.price } as any
    } else {
      lstTmp[index] = { ...lstTmp[index], [key]: newVal } as any
    }

    setLstProductDetails(lstTmp)
  }

  const getTotalAmoutWithoutTex = () => {
    let total: any = 0
    let newArr = []
    lstProductDetails.map((obj) => {
      let tmp =
        parseFloat(obj.rate) && parseFloat(obj.quantity)
          ? parseFloat(obj.rate) * parseFloat(obj.quantity)
          : 0

      total = total + (tmp * 1000)
    })
    return total
  }

  const getSGSTValue = () => {
    let total: any = getTotalAmoutWithoutTex()
    let sGst = (total / 100) * valueSGST
    return sGst
  }

  const getCGSTValue = () => {
    let total: any = getTotalAmoutWithoutTex()
    let cGst = (total / 100) * valueCGST
    return cGst
  }

  const getIGSTValue = () => {
    let total: any = getTotalAmoutWithoutTex()
    let iGst = (total / 100) * valueIGST
    return iGst
  }

  const getTotalAmoutWithTex = () => {
    let total: any =
      getTotalAmoutWithoutTex() +
      (formik.values.state_id !== '3749b049-6434-4e8b-8358-478251a33f8d'
        ? getIGSTValue()
        : getSGSTValue() + getCGSTValue())
    return total.toFixed(2)
  }

  const getMasterFirmData = (id: string) => {
    console.log(id, 'id getMasterFirmData')
    let selected = lstFirmMaster.filter((obj: any) => {
      console.log(obj, '----------obj')
      if (obj.id == id) return obj
    })
    console.log(selected, 'selected')
    if (selected.length) {
      return selected[0]
    } else {
      return []
    }
  }
  const dateForPicker = (dateString: any) => {
    return moment(new Date(dateString)).format('YYYY-MM-DD')
  }

  const { getRootProps, getInputProps, open, acceptedFiles } = useDropzone({
    // Disable click and keydown behavior
    noClick: true,
    noKeyboard: true,
  })

  const uploadQOFile = async (type: any, file: any) => {
    if(type == "vehicle_assigned_attachment"){
      if(file.type !== "application/pdf"){
        setSessionMessage({ type: 'danger', message: "Only PDF Allowed", show: true })
        setTimeout(() =>{
          setSessionMessage({
            type: '',
            message: '',
            show: false,
          })
        },4000)
        return 
      }
    }
    let formData = new FormData()
    formData.append(`invoice`, file)
    let filePath: any = await uploadFile(formData)
    formik.setFieldValue(type, filePath.newFilename)
  }
  const setBuyerAddress = (bsdata: any, type:String, oldData:any = {} ) => {
    setSelectedMasterFirm(bsdata)
    let personData = bsdata?.firmPersonInfo.map((x: any, i: any) => {
      return {
        label: x?.name ?? "",
        value: x?.id ?? "",
        whatsapp_number: x?.whatsapp_number ?? "",
        email: x?.email ?? ""
      }
    }) ?? []

    if (type == "edit" && oldData?.firmBuyerInfo) {
      if (!personData.find((x: any) => x.value == oldData?.firmBuyerInfo.id)) {
        personData.push({ label: oldData?.firmBuyerInfo?.name ?? "", value: oldData?.firmBuyerInfo?.id ?? "", whatsapp_number: oldData?.firmBuyerInfo?.whatsapp_number ?? "", email: oldData?.firmBuyerInfo?.email ?? ""})
      }
    }
    else if (type == "add" && oldDataById?.firmBuyerInfo && id) {
      if (bsdata?.id == oldDataById?.buyerSellerInfo?.id && !personData.find((x: any) => x.value == oldDataById?.firmBuyerInfo?.id)) {
        personData.push({ label: oldDataById?.firmBuyerInfo?.name ?? "", value: oldDataById?.firmBuyerInfo?.id ?? "", whatsapp_number: oldDataById?.firmBuyerInfo?.whatsapp_number ?? "", email: oldDataById?.firmBuyerInfo?.email ?? "" })
      }
    }


    let buyerSellerBillingAddress = bsdata?.buyerSellerPrimaryBillingInfo?.map((x: any) => {
      // return {
      //   label: x?.addressLocationInfo?.name ?? "",
      //   value: x?.addressLocationInfo?.id ?? "",
      //   state_id: x?.state_id ?? "",
      //   id: x?.id ?? ""
      // }
      return {
        label: x?.city ?? "",
        value: x?.id ?? "",
        district: x?.addressLocationInfo?.name ?? "",
        state_id: x?.state_id ?? "",
        id: x?.addressLocationInfo?.id ?? ""
      }
    }) ?? []

    let buyerSellerShippingAddress = bsdata?.buyerSellerPrimaryShippingInfo?.map((x: any) => {
      return {
        label: x?.address_line_1 ?? "",
        value: x?.id ?? "",
        address_line_1: x?.address_line_1 ?? "",
        address_line_2: x?.address_line_2 ?? "",
        district_id: x?.district_id ?? "",
        district: x?.addressLocationInfo?.name ?? "",
        state_id: x?.state_id ?? "",
        country: x?.country ?? "",
        pincode: x?.pincode ?? "",
        state: x?.addressLocationInfo?.stateInfo?.name ?? "",
        city: x?.city ?? "",
        id: x?.id ?? ""
      }
    }) ?? []

    if (type == "edit" && oldData?.billingAddressLocationInfo) {
      if (!buyerSellerBillingAddress.find((x: any) => x.id == oldData?.billingAddressLocationInfo?.district_id && x.value == oldData?.buyer_billing_address_id)) {
        // buyerSellerBillingAddress.push({ 
        //   label: oldData?.billingAddressLocationInfo?.addressLocationInfo?.name, 
        //   value: oldData?.billingAddressLocationInfo?.addressLocationInfo?.id, 
        //   id: oldData?.buyer_billing_address_id, 
        //   state_id: oldData?.billingAddressLocationInfo?.state_id, 
        // })
        // return {
        //   label: x?.city ?? "",
        //   value: x?.id ?? "",
        //   district: x?.addressLocationInfo?.name ?? "",
        //   state_id: x?.state_id ?? "",
        //   id: x?.addressLocationInfo?.id ?? ""
        // }
        buyerSellerBillingAddress.push({
          label: oldData?.billingAddressLocationInfo?.city ?? "", 
          value: oldData?.buyer_billing_address_id ?? "", 
          id: oldData?.billingAddressLocationInfo?.addressLocationInfo?.id ?? "", 
          district: oldData?.billingAddressLocationInfo?.addressLocationInfo?.name ?? "", 
          state_id: oldData?.billingAddressLocationInfo?.state_id ?? "", 
        })
      }
    }

    // else if (type == "add" && oldDataById?.billingAddressLocationInfo && id) {
    //   if (bsdata?.id == oldDataById?.buyerSellerInfo?.id && !buyerSellerBillingAddress.find((x: any) => x.value == oldDataById?.billingAddressLocationInfo?.billingAddressLocationInfo?.id)) {
    //     buyerSellerBillingAddress.push({ 
    //       label: oldDataById?.billingAddressLocationInfo?.addressLocationInfo?.name, 
    //       value: oldDataById?.billingAddressLocationInfo?.addressLocationInfo?.id, 
    //       id: oldDataById?.buyer_billing_address_id, 
    //       state_id: oldDataById?.billingAddressLocationInfo?.state_id, 
    //     })
    //   }
    // }

    if (type == "edit" && oldData?.shippingAddressLocationInfo) {
      if (!buyerSellerShippingAddress.find((x: any) => x.value == oldData?.shippingAddressLocationInfo?.id)) {
        buyerSellerShippingAddress.push({
          label: oldData?.shippingAddressLocationInfo?.address_line_1 ?? "",
          value: oldData?.buyer_shipping_address_id ?? "",
          address_line_1: oldData?.shippingAddressLocationInfo?.address_line_1 ?? "",
          address_line_2: oldData?.shippingAddressLocationInfo?.address_line_2 ?? "",
          district_id: oldData?.shippingAddressLocationInfo?.district_id ?? "",
          district: oldData?.shippingAddressLocationInfo?.addressLocationInfo?.name ?? "",
          state_id: oldData?.shippingAddressLocationInfo?.state_id ?? "",
          country: oldData?.shippingAddressLocationInfo?.country ?? "India",
          pincode: oldData?.shippingAddressLocationInfo?.pincode ?? "",
          state: oldData?.shippingAddressLocationInfo?.addressLocationInfo?.stateInfo?.name ?? "",
          city: oldData?.shippingAddressLocationInfo?.city ?? "",
          id: oldData?.buyer_shipping_address_id ?? ""
        })
      }
      
    }
    else if (type == "add" && oldDataById?.shippingAddressLocationInfo && id) {
      if (bsdata?.id == oldDataById?.buyerSellerInfo?.id && !buyerSellerShippingAddress.find((x: any) => x.value == oldDataById?.shippingAddressLocationInfo?.id)) {
        buyerSellerShippingAddress.push({
          label: oldDataById?.shippingAddressLocationInfo?.address_line_1 ?? "",
          value: oldDataById?.buyer_shipping_address_id ?? "",
          address_line_1: oldDataById?.shippingAddressLocationInfo?.address_line_1 ?? "",
          address_line_2: oldDataById?.shippingAddressLocationInfo?.address_line_2 ?? "",
          district_id: oldDataById?.shippingAddressLocationInfo?.district_id ?? "",
          district: oldDataById?.shippingAddressLocationInfo?.addressLocationInfo?.name ?? "",
          state_id: oldDataById?.shippingAddressLocationInfo?.state_id ?? "",
          country: oldDataById?.shippingAddressLocationInfo?.country ?? "India",
          pincode: oldDataById?.shippingAddressLocationInfo?.pincode ?? "",
          state: oldDataById?.shippingAddressLocationInfo?.addressLocationInfo?.stateInfo?.name ?? "",
          city: oldDataById?.shippingAddressLocationInfo?.city ?? "",
          id: oldDataById?.buyer_shipping_address_id ?? ""
        })
      }
    }

    setPersonList(personData)
    setBillingAddressList(buyerSellerBillingAddress)
    setShippingAddressList(buyerSellerShippingAddress)
  }

  return (
    <>
      {loadingGetData || loadingList ? (
        <div
          style={{ height: '550px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}
        >
          <Spinner animation='border' />
        </div>
      ) : (
        <form
          className='form w-100'
          onSubmit={formik.handleSubmit}
          noValidate
          id='kt_login_signin_form'
        >
          <Row className='mb-6'>
            {/* {JSON.stringify(formik.values)} */}
            <Col xl={4}>
              <Form.Group className='mb-3'>
                <Form.Label className='form-label'> Inquiry Number </Form.Label>
                <Select
                  options={inquiryId}
                  className='custom_select'
                  classNamePrefix='Select'
                  placeholder='Select Inquiry Number'
                  isDisabled={ !dataInquiryListing.some((item: any) => item.id === formik.values.inquiry_id && [1, 2, 3].includes(item.inquiry_status)) && id }
                  onInputChange={(e: any) => {
                    if (e) setSearchForInquiry(e)
                  }}
                  value={selectedValueInquiry(formik.values.inquiry_id)}
                  onChange={(e: any) => {
                    let objTmp: any = getMasterInqObject(e.value)
                    let objBuyerSeller = objTmp?.buyerSellerInfo ?? {}
                    formik.setFieldValue('inquiry_id', e.value)
                    formik.setFieldValue('buyer_id', objBuyerSeller?.id)
                    formik.setFieldValue('contact_person_name', objTmp?.buyerSellerInfo?.business_contact_name ?? "");
                    formik.setFieldValue('firm_person_id', objTmp?.firmBuyerInfo?.id ?? "");
                    formik.setFieldValue('whatsapp_number', objTmp?.firmBuyerInfo?.whatsapp_number ?? "");
                    formik.setFieldValue('email', objTmp?.firmBuyerInfo?.email ?? "");
                    formik.setFieldValue('reference', "")
                    formik.setFieldValue('destination_id', objTmp?.billingAddressLocationInfo?.district_id                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                )
                    formik.setFieldValue('state_id', objTmp?.billingAddressLocationInfo?.state_id)
                    formik.setFieldValue('buyer_billing_address_id',objTmp?.billingAddressLocationInfo?.id)
                      if (objTmp?.shippingAddressLocationInfo) {
                        const shippingInfo = objTmp.shippingAddressLocationInfo;
                        let matchingShippingInfo = objTmp?.buyerSellerInfo?.buyerSellerPrimaryShippingInfo?.find(
                          (info: any) => info.district_id === shippingInfo.district_id 
                        );
                        formik.setFieldValue('buyer_shipping_address_id', shippingInfo.id ?? "");
                        formik.setFieldValue('shipping_address.address_line_1', shippingInfo.address_line_1 ?? "");
                        formik.setFieldValue('shipping_address.address_line_2', shippingInfo.address_line_2 ?? "");
                        formik.setFieldValue('shipping_address.country', shippingInfo.country ?? "");
                        formik.setFieldValue(`shipping_address.state_id`, shippingInfo.state_id ?? "");
                        formik.setFieldValue(`shipping_address.state`, matchingShippingInfo.addressLocationInfo?.stateInfo?.name ?? "");
                        formik.setFieldValue(`shipping_address.district_id`, shippingInfo.district_id ?? "");
                        formik.setFieldValue(`shipping_address.district`, matchingShippingInfo.addressLocationInfo?.name ?? "");
                        formik.setFieldValue('shipping_address.city', shippingInfo.city ?? "");
                        formik.setFieldValue('shipping_address.pincode', shippingInfo.pincode ?? "");
                      }else{
                        formik.setFieldValue('buyer_shipping_address_id', '')
                        formik.setFieldValue('shipping_address.address_line_1', '')
                        formik.setFieldValue('shipping_address.address_line_2', '')
                        formik.setFieldValue('shipping_address.country', '')
                        formik.setFieldValue(`shipping_address.state_id`, '')
                        formik.setFieldValue(`shipping_address.state`, '')
                        formik.setFieldValue(`shipping_address.district_id`, '')
                        formik.setFieldValue(`shipping_address.district`, '')
                        formik.setFieldValue('shipping_address.city', '')
                        formik.setFieldValue('shipping_address.pincode', '')
                      }
                    let lstTmp = objTmp.inquiryInfo.map((obj: any) => {
                      return { ...obj, quantity: obj.product_quantity }
                    })
                    setLstProductDetails(lstTmp)
                    setBuyerAddress(lstFirm.find((x: any) => x.value == objBuyerSeller?.id),"add")
                  }}
                />
              </Form.Group>
            </Col>
            <Col xl={4}>
              <Form.Group className='mb-3'>
                <Form.Label className='required form-label'> Date </Form.Label>
                <Form.Control
                  type='Date'
                  className='form-control-solid'
                  placeholder='Select Date'
                  value={formik.values.date ? dateForPicker(formik.values.date) : ''}
                  onChange={(e: any) => {
                    formik.setFieldValue('date', e.target.value)
                  }}
                />
                {formik.touched.date && formik.errors.date && (
                  <div className='fv-plugins-message-container'>
                    <span role='alert' className={'text-danger'}>
                      {formik.errors.date}
                    </span>
                  </div>
                )}
              </Form.Group>
            </Col>
       
            <Col xl={4}>
              <Form.Group className='mb-3'>
                <Form.Label className='form-label'> Reference </Form.Label>
                <Form.Control
                  type='text'
                  className='form-control-solid'
                  placeholder='Reference'
                  defaultValue={formik.values.reference}
                  onChange={(e: any) => {
                    formik.setFieldValue('reference', e.target.value)
                  }}
                />
              </Form.Group>
            </Col>
          </Row>

          <h2 className='mb-3'> Buyer Details </h2>
          <Row className='mb-5'>
            <Col xl={4} className='mb-6'>
              <Form.Group className=' '>
                <Form.Label className='required fw-bold fs-6 mb-2 form-label'>
                  Firm Name
                </Form.Label>
                <Select
                  options={lstFirm}
                  className='custom_select'
                  classNamePrefix='Select'
                  placeholder='Select Firm'
                  value={selectedValueFirm(formik.values.buyer_id)}
                  onChange={(e:any)=>{
                    setBuyerAddress(lstFirm.find((x: any) => x.value == e?.value),"add")
                    formik.setFieldValue('buyer_id', e?.value ?? "")
                    formik.setFieldValue('contact_person_name', e?.business_contact_name?? "")
                    formik.setFieldValue('firm_person_id', "")
                    formik.setFieldValue('whatsapp_number', "")
                    formik.setFieldValue('email', "")
                    formik.setFieldValue('reference', "")
                    formik.setFieldValue('destination_id', '')
                    formik.setFieldValue('state_id', '')
                    formik.setFieldValue('buyer_billing_address_id', '')
                    formik.setFieldValue('buyer_shipping_address_id', '')
                    formik.setFieldValue('shipping_address.address_line_1', '')
                    formik.setFieldValue('shipping_address.address_line_2', '')
                    formik.setFieldValue('shipping_address.country', '')
                    formik.setFieldValue(`shipping_address.state_id`, '')
                    formik.setFieldValue(`shipping_address.state`, '')
                    formik.setFieldValue(`shipping_address.district_id`, '')
                    formik.setFieldValue(`shipping_address.district`, '')
                    formik.setFieldValue('shipping_address.city', '')
                    formik.setFieldValue('shipping_address.pincode', '')
                  }}
                  // isDisabled={true}
                />
                {formik.touched.buyer_id && formik.errors.buyer_id && (
                  <div className='fv-plugins-message-container'>
                    <span role='alert' className={'text-danger'}>
                      {formik.errors.buyer_id}
                    </span>
                  </div>
                )}
              </Form.Group>
            </Col>
            <Col xl={4} className='mb-6'>
              <Form.Group className=' '>
                <Form.Label className=' required fw-bold fs-6 mb-2 form-label'>
                  Buyer Name
                </Form.Label>
                <Select
                  options={personList}
                  className='custom_select'
                  classNamePrefix='Select'
                  placeholder='Select buyer'
                  value={selectedValueSeller(formik.values.firm_person_id)}
                  onChange={(e: any) => {
                    formik.setFieldValue('firm_person_id', e.value)
                    formik.setFieldValue('email', e.email)
                    formik.setFieldValue('whatsapp_number', e.whatsapp_number)
                  }}
                />
                {formik.touched.firm_person_id && formik.errors.firm_person_id && (
                  <div className='fv-plugins-message-container'>
                    <span role='alert' className={'text-danger'}>
                      {formik.errors.firm_person_id}
                    </span>
                  </div>
                )}
              </Form.Group>
            </Col>
            <Col xl={4} className='mb-6'>
              <Form.Group className='mb-3'>
                <Form.Label className='required form-label'> Whatsapp Number </Form.Label>
                <Form.Control
                  type='text'
                  className='form-control-solid'
                  placeholder='Whatsapp number'
                  value={formik.values.whatsapp_number}
                />
                {formik.touched.whatsapp_number && formik.errors.whatsapp_number && (
                  <div className='fv-plugins-message-container'>
                    <span role='alert' className={'text-danger'}>
                      {formik.errors.whatsapp_number}
                    </span>
                  </div>
                )}
              </Form.Group>
            </Col>
            <Col xl={4} className='mb-6'>
              <Form.Group className='mb-3'>
                <Form.Label className='form-label'> Email Id </Form.Label>
                <Form.Control
                  type='text'
                  className='form-control-solid'
                  placeholder='Email Id'
                  value={formik.values.email}
                />
                {formik.touched.email && formik.errors.email && (
                  <div className='fv-plugins-message-container'>
                    <span role='alert' className={'text-danger'}>
                      {formik.errors.email}
                    </span>
                  </div>
                )}
              </Form.Group>
            </Col>

            <Col xl={4} className='mb-6'>
              <Form.Group className=''>
                <Form.Label className='required form-label'> Contact Person Name </Form.Label>
                <Form.Control
                  type='text'
                  className='form-control-solid'
                  placeholder='Contact person Name'
                  value={formik.values.contact_person_name && formik.values.contact_person_name}
                  onChange={(e: any) => {
                    formik.setFieldValue('contact_person_name', e.target.value)
                  }}
                />
                {formik.touched.contact_person_name && formik.errors.contact_person_name && (
                  <div className='fv-plugins-message-container'>
                    <span role='alert' className={'text-danger'}>
                      {formik.errors.contact_person_name}
                    </span>
                  </div>
                )}
              </Form.Group>
            </Col>
            <Col xl={4} className='mb-6'>
              {/* purvik 1*/}
              <Form.Group className=' '>
                <Form.Label className='required form-label'> Billing City </Form.Label>
                <Select
                  options={billingAddressList}
                  className='custom_select'
                  classNamePrefix='Select'
                  placeholder='Select Billing City'
                  value={selectedValueBillingLocation(formik.values.buyer_billing_address_id)}
                  onChange={(e: any) => {
                    formik.setFieldValue('destination_id', e.id)
                    formik.setFieldValue('state_id', e.state_id)
                    formik.setFieldValue('buyer_billing_address_id', e.value)
                  }}
                />
                {formik.touched.destination_id && formik.errors.destination_id && (
                  <div className='fv-plugins-message-container'>
                    <span role='alert' className={'text-danger'}>
                      {formik.errors.destination_id}
                    </span>
                  </div>
                )}
              </Form.Group>
            </Col>

            <Col xl={4} className='mb-6'>
              <Form.Label className='required form-label'> Select Transporter </Form.Label>
              <Select
                options={lstTransport}
                className='custom_select'
                classNamePrefix='Select'
                placeholder='Select Transporter'
                value={selectedTransportList(formik.values.transport_id)}
                onChange={(e: any) => {
                  formik.setFieldValue('transport_id', e.value)
                }}
              />
              {formik.touched.transport_id &&
                formik.errors.transport_id && (
                  <div className='fv-plugins-message-container'>
                    <span role='alert' className={'text-danger'}>
                      {formik.errors.transport_id}
                    </span>
                  </div>
                )}
            </Col>
          </Row>

          <Row className='mt-10'>
            <h2 className=''> Shipping Address </h2>
          </Row>
          <Row className='mt-10 mb-6'>
            <Col xl={4}>
              <Form.Label className='required form-label'> Address Line 1</Form.Label>
              <Select
                options={shippingAddressList}
                className='custom_select'
                placeholder='Select Shipping Address'
                value={selectedValueShippingLocation(formik.values?.buyer_shipping_address_id)}
                onChange={(e: any) => {
                  formik.setFieldValue('buyer_shipping_address_id', e.value)
                  formik.setFieldValue('shipping_address.address_line_1', e.address_line_1)
                  formik.setFieldValue('shipping_address.address_line_2', e.address_line_2)
                  formik.setFieldValue('shipping_address.country', e.country)
                  formik.setFieldValue(`shipping_address.state_id`, e.state_id)
                  formik.setFieldValue(`shipping_address.state`, e.state)
                  formik.setFieldValue(`shipping_address.district_id`, e.district_id)
                  formik.setFieldValue(`shipping_address.district`, e.district)
                  formik.setFieldValue('shipping_address.city', e.city)
                  formik.setFieldValue('shipping_address.pincode', e.pincode)
                }}
              />
              {formik.touched.buyer_shipping_address_id &&
                formik.errors.buyer_shipping_address_id && (
                  <div className='fv-plugins-message-container'>
                    <span role='alert' className={'text-danger'}>
                      {formik.errors.buyer_shipping_address_id}
                    </span>
                  </div>
                )}
            </Col>
            <Col xl={4}>
              <Form.Label className='form-label'> Address Line 2</Form.Label>
              <Form.Control
                type='text'
                className='form-control-solid mb-3'
                placeholder='Shipping Address'
                value={formik.values.shipping_address?.address_line_2}
                onChange={(e: any) => {
                  formik.setFieldValue('shipping_address.address_line_2', e.target.value)
                }}
                disabled={true}
              />
            </Col>
            <Col xl={4}>
              <Form.Label className='form-label fw-bold'> Country </Form.Label>
              <Form.Control
                type='text'
                className='form-control-solid'
                placeholder='India'
                disabled={true}
                value={formik.values.shipping_address?.country}
                onChange={(e: any) => {
                  formik.setFieldValue('shipping_address.country', e.target.value)
                }}
              />
              {formik.touched.shipping_address?.country && formik.errors.shipping_address?.country && (
                <div className='fv-plugins-message-container'>
                  <span role='alert' className={'text-danger'}>
                    {formik.errors.shipping_address?.country}
                  </span>
                </div>
              )}
            </Col>
          </Row>

          <Row className='mb-10'>
            {/* purvik 2 */}
            <Col xl={4}>
              <Row>
                <Col xl={6}>
                  <Form.Label className='form-label'> State </Form.Label>
                  <Form.Control
                    type='text'
                    className='form-control-solid'
                    placeholder='State'
                    disabled={true}
                    value={formik.values.shipping_address?.state}
                  />
                  {formik.touched.shipping_address?.state && formik.errors.shipping_address?.state && (
                    <div className='fv-plugins-message-container'>
                      <span role='alert' className={'text-danger'}>
                        {formik.errors.shipping_address?.state}
                      </span>
                    </div>
                  )}
                </Col>
                <Col xl={6}>
                  <Form.Label className='required form-label'> District </Form.Label>
                  <Form.Control
                    type='text'
                    className='form-control-solid'
                    placeholder='District'
                    disabled={true}
                    value={formik.values.shipping_address?.district}
                  />
                  {formik.touched.shipping_address?.district &&
                    formik.errors.shipping_address?.district && (
                      <div className='fv-plugins-message-container'>
                        <span role='alert' className={'text-danger'}>
                          {formik.errors.shipping_address?.district}
                        </span>
                      </div>
                    )}
                </Col>
              </Row>
            </Col>

            <Col xl={4}>
              <Row>
                <Col xl={6}>
                  <Form.Label className='required form-label fw-bold'> City </Form.Label>
                  <Form.Control
                    type='text'
                    className='form-control-solid'
                    placeholder='City'
                    value={formik.values.shipping_address?.city}
                    onChange={(e: any) => {
                      formik.setFieldValue('shipping_address.city', e.target.value)
                    }}
                    disabled={true}
                  />
                  {formik.touched.shipping_address?.city && formik.errors.shipping_address?.city && (
                    <div className='fv-plugins-message-container'>
                      <span role='alert' className={'text-danger'}>
                        {formik.errors.shipping_address?.city}
                      </span>
                    </div>
                  )}
                </Col>
                <Col xl={6}>
                  <Form.Label className='required form-label'> Pincode </Form.Label>
                  <Form.Control
                    type='text'
                    className='form-control-solid me-3'
                    placeholder='Pincode'
                    value={formik.values.shipping_address?.pincode}
                    onChange={(e: any) => {
                      formik.setFieldValue('shipping_address.pincode', e.target.value)
                    }}
                    disabled={true}
                  />
                  {formik.touched.shipping_address?.pincode &&
                    formik.errors.shipping_address?.pincode && (
                      <div className='fv-plugins-message-container'>
                        <span role='alert' className={'text-danger'}>
                          {formik.errors.shipping_address?.pincode}
                        </span>
                      </div>
                    )}
                </Col>
              </Row>
              
            </Col>
            <Row className="mt-4">
              <Col xl={4}>
                <Form.Group className='mb-3 d-flex align-items-center'>
                  <Form.Label className=' form-label'>Payment Terms</Form.Label>
                </Form.Group>
                <Col className='d-flex mt-3'>
                  <Form.Group className='mb-3 d-flex align-items-center me-5'>
                  <Form.Check
                      className='me-5 fs-5 border-radius-0 form-control-solid text-grey-800'
                      id={'advancePayment'}
                      label='Advance Payment'
                      type='radio'
                      checked={formik.values.payment_terms == 3}
                      onChange={() => {
                        formik.setFieldValue('payment_terms', 3)
                        formik.setFieldValue('payment_terms_note', "")
                      }}
                    />
                    <Form.Check
                      className='me-5 fs-5 border-radius-0 form-control-solid text-grey-800'
                      id={'payondelivery'}
                      label='Pay On Delivery'
                      defaultChecked
                      type='radio'
                      checked={formik.values.payment_terms == 0}
                      onChange={() => {
                        formik.setFieldValue('payment_terms', 0)
                      }}
              
                      name='payment_terms'
                    />
                    <Form.Check
                      className='me-5 fs-5 border-radius-0 form-control-solid text-grey-600'
                      id={'note'}
                      label='Note'
                      type='radio'
                      name='payment_terms'
                      checked={formik.values.payment_terms == 1}
                      onChange={() => {
                        formik.setFieldValue('payment_terms', 1)
                      }}
                    />
                  </Form.Group>
                </Col>
              </Col>
              {(formik.values.payment_terms == true ) ? (  
                <>
                <Col xl={4}>
                    <Form.Group className='mb-3'>
                      <Form.Label className='required form-label'> Note </Form.Label>
                      <Form.Control
                        type='text'
                        className='form-control-solid'
                        placeholder='Note'
                        value={formik.values.payment_terms_note}
                        onChange={(e: any) => {
                          if(e.target.value.length <= 50 ){
                            formik.setFieldValue('payment_terms_note', e.target.value)
                          }
                        }}
                      />
                      <Form.Label className='mt-1 d-flex justify-content-between form-label'>
                        <span role='alert' className={'text-danger text-left'} >
                          { (formik.touched.payment_terms_note && formik.errors.payment_terms_note) ? formik.errors.payment_terms_note : ""}
                        </span>
                        <span>{' '}</span>
                        <span className={`text-right ${formik?.values?.payment_terms_note?.length > 49 ? 'text-danger' : ''}`}> {
                          formik?.values?.payment_terms_note?.length == 0 ? "Maximum 50 Characters" : 
                          `${50 - formik?.values?.payment_terms_note?.length} Characters left`}</span>
                      </Form.Label>
                    </Form.Group>
                </Col>
                </>):""}    
              </Row>
          </Row>

          <Row>
            <Table responsive className='quot-table'>
              <thead>
                <tr>
                  <th style={{ width: '12%' }}>Sr. No.</th>
                  <th>
                    Product Name <span className='text-danger fs-5'> * </span>
                  </th>
                  <th>
                    {' '}
                    HSN Code <span className='text-danger fs-5'> * </span>{' '}
                  </th>
                  <th style={{ width: '16%' }}>
                    Qty(MT) <span className='text-danger fs-5'> * </span>{' '}
                  </th>
                  <th style={{ width: '14%' }}>
                    Rate/Kg <span className='text-danger fs-5'> * </span>{' '}
                  </th>
                  <th style={{ width: '16%' }}>Amount </th>
                  <th style={{ width: '4%' }}> </th>
                </tr>
              </thead>
              <tbody>
                {lstProductDetails.map((objProduct, index) => {
                  let objMain = getMasterProductObject(objProduct.product_id) as any
                  console.log(objProduct, 'objProduct')

                  // @ts-ignore
                  // @ts-ignore
                  // @ts-ignore
                  return (
                    <tr id='row1'>
                      <td>{index + 1}</td>
                      <td>
                        <Form.Group>
                          <Select
                            options={lstProduct}
                            className='custom_select'
                            classNamePrefix='Select'
                            placeholder='Select Product'
                            value={selectedValueProduct(objProduct.product_id)}
                            onChange={(e: any) => {
                              onChangeProductDetail(index, 'product_id', e.value)
                              formik.setFieldValue(`quotation_details.${index}.product_id`, e.value)
                            }}
                          />
                          {/*{formik.touched.quotation_details &&*/}
                          {/*    formik.errors.quotation_details?.length >= 0 && formik.errors.quotation_details.map((e:any)=>{*/}
                          {/*    return  <div className='fv-plugins-message-container'>*/}
                          {/*            <span role='alert' className={'text-danger'}>*/}
                          {/*              {e}*/}
                          {/*            </span>*/}
                          {/*          </div>*/}
                          {/*})*/}

                          {/*}*/}
                          {/*{formik.touched.address_line_1 && formik.errors.address_line_1 && (*/}
                          {/*    <div className='fv-plugins-message-container'>*/}
                          {/*        <span role='alert' className={'text-danger'}>*/}
                          {/*          {formik.errors.address_line_1}*/}
                          {/*        </span>*/}
                          {/*    </div>*/}
                          {/*)}*/}
                        </Form.Group>
                      </td>
                      <td>
                        <Form.Control
                          type='text'
                          className='form-control-solid'
                          placeholder='HSN Code'
                          value={objMain.hsn}
                        />
                      </td>

                      <td>
                        <Form.Group className=''>
                          <Form.Control
                            type='number'
                            className='form-control-solid'
                            placeholder='Qty (MT)'
                            value={objProduct.quantity}
                            onChange={(e: any) => {
                              let decimalPattern = /^\d+(\.\d{0,3})?$/;
                              if(decimalPattern.test(e.target.value)){
                                let n = e.target.value
                                onChangeProductDetail(index, 'quantity', n)
                              }else if(e.target.value == ""){
                                onChangeProductDetail(index, 'quantity', parseFloat(e.target.value))
                              }
                            }}
                          />
                        </Form.Group>
                      </td>

                      <td>
                        <Form.Group className=''>
                          <Form.Control
                            type='number'
                            className='form-control-solid'
                            placeholder='Rate'
                            value={objProduct.rate}
                            onChange={(e: any) => {
                              onChangeProductDetail(index, 'rate', parseFloat(e.target.value))
                            }}
                          />
                        </Form.Group>
                      </td>
                      <td>
                        <Form.Group className=''>
                          <Form.Control
                            type='text'
                            className='form-control-solid'
                            placeholder='Amount'
                            value={
                              parseFloat(objProduct.rate) && parseFloat(objProduct.quantity)
                                ? (
                                  parseFloat(objProduct.rate) *
                                  parseFloat(objProduct.quantity) *
                                  1000
                                ).toFixed(2)
                                : 0
                            }
                          />
                        </Form.Group>
                      </td>
                      <td className='text-center align-items-center'>
                        <div>
                          {lstProductDetails.length > 1 && lstProductDetails.length - 1 != index ? (
                            <a
                              onClick={() => {
                                let lstTmp = lstProductDetails.filter((obj, i) => i != index)
                                setLstProductDetails(lstTmp)
                              }}
                              className='btn btn-sm btn-icon btn-color-danger'
                            >
                              <KTSVG
                                path='/media/icons/duotune/general/gen036.svg'
                                className='svg-icon-muted svg-icon-2hx'
                              />
                            </a>
                          ) : (
                            <a
                              // href='#'
                              onClick={() => {
                                setLstProductDetails([
                                  ...lstProductDetails,
                                  {
                                    quantity: '',
                                    rate: '',
                                    product_id: '',
                                  },
                                ])
                              }}
                              className='btn btn-sm btn-icon  btn-color-success'
                            >
                              <KTSVG
                                path='/media/icons/duotune/general/gen035.svg'
                                className='svg-icon-muted svg-icon-2hx me-2'
                              />
                            </a>
                          )}
                          {/* <a
                          href='#row2'
                          onClick={() => {
                            // setIsShowSourceFild(true)
                          }}
                          className='btn btn-sm btn-icon mt-3 btn-color-success'
                        >
                          <KTSVG
                            path='/media/icons/duotune/general/gen035.svg'
                            className='svg-icon-muted svg-icon-2hx me-2'
                          />
                        </a> */}
                        </div>
                      </td>
                    </tr>
                  )
                })}

                {/* {isShowSourceFild && (
                <tr id='row2'>
                  <td>2</td>
                  <td>
                    <Select
                      options={productList}
                      className='custom_select'
                      classNamePrefix='Select'
                      placeholder='Select Product'
                    />
                  </td>
                  <td>
                    <Form.Control
                      type='text'
                      disabled
                      className='required form-control-solid'
                      placeholder='HSN Code'
                    />
                  </td>

                  <td>
                    <Form.Group className=''>
                      <Form.Control
                        type='text'
                        className='required form-control-solid'
                        placeholder='Qty (MT)'
                      />
                    </Form.Group>
                  </td>

                  <td>
                    <Form.Group className=''>
                      <Form.Control
                        type='text'
                        className='required form-control-solid'
                        placeholder='Rate/Kg'
                      />
                    </Form.Group>
                  </td>
                  <td>
                    <Form.Group className=''>
                      <Form.Control
                        type='text'
                        className='required form-control-solid'
                        placeholder='Amount'
                      />
                    </Form.Group>
                  </td>
                  <td className='text-center pt-6 align-items-center'>
                    <div className='d-flex'>
                      <a
                        href='#row1'
                        onClick={() => {
                          // setIsShowSourceFild(false)
                          // handleClose()
                        }}
                        className='btn btn-sm btn-icon mt-3 btn-color-danger'
                      >
                        <KTSVG
                          path='/media/icons/duotune/general/gen036.svg'
                          className='svg-icon-muted svg-icon-2hx'
                        />
                      </a>
                      <a
                        href='#'
                        onClick={() => {
                          // setIsShowSourceFild(true)
                          // handleClose()
                        }}
                        className='btn btn-sm btn-icon mt-3 btn-color-success'
                      >
                        <KTSVG
                          path='/media/icons/duotune/general/gen035.svg'
                          className='svg-icon-muted svg-icon-2hx'
                        />
                      </a>
                    </div>
                  </td>
                </tr>
              )} */}

                <tr>
                  <td> Total </td>
                  <td> &nbsp; </td>
                  <td> &nbsp; </td>
                  <td> &nbsp; </td>
                  <td> &nbsp; </td>
                  <td> &nbsp; </td>
                  <td> &nbsp; </td>
                </tr>
                <tr>
                  <td rowSpan={7} colSpan={4}>
                    {' '}
                    &nbsp;{' '}
                  </td>
                  <td style={{ verticalAlign: 'middle' }}> Amount (Without Tax) </td>
                  <td colSpan={2}>
                    {' '}
                    <Form.Group className=''>
                      <Form.Control
                        type='text'
                        className='required form-control-solid'
                        value={getTotalAmoutWithoutTex().toFixed(2)}
                        placeholder='Amount'
                      />
                    </Form.Group>{' '}
                  </td>
                </tr>
                {formik.values.state_id !== '3749b049-6434-4e8b-8358-478251a33f8d' ? (
                  <tr>
                    <td style={{ verticalAlign: 'middle' }}> IGST </td>
                    <td colSpan={2}>
                      {' '}
                      <Form.Group className=''>
                        <Form.Control
                          type='text'
                          className='required form-control-solid'
                          placeholder='IGST'
                          value={getIGSTValue().toFixed(2)}
                        />
                      </Form.Group>{' '}
                    </td>
                  </tr>
                ) : (
                  <>
                    <tr>
                      <td style={{ verticalAlign: 'middle' }}> CGST </td>
                      <td colSpan={2}>
                        {' '}
                        <Form.Group className=''>
                          <Form.Control
                            type='text'
                            className='required form-control-solid'
                            placeholder='CGST'
                            value={getCGSTValue().toFixed(2)}
                          />
                        </Form.Group>{' '}
                      </td>
                    </tr>
                    <tr>
                      <td style={{ verticalAlign: 'middle' }}> SGST </td>
                      <td colSpan={2}>
                        {' '}
                        <Form.Group className=''>
                          <Form.Control
                            type='text'
                            className='required form-control-solid'
                            placeholder='SGST'
                            value={getSGSTValue().toFixed(2)}
                          />
                        </Form.Group>{' '}
                      </td>
                    </tr>
                  </>
                )}

                <tr>
                  <td style={{ verticalAlign: 'middle' }}> Total Amount (With Tax) </td>
                  <td colSpan={2}>
                    {' '}
                    <Form.Group className=''>
                      <Form.Control
                        type='text'
                        className='required form-control-solid'
                        placeholder='Total Amount'
                        value={getTotalAmoutWithTex()}
                      />
                    </Form.Group>{' '}
                  </td>
                </tr>
              </tbody>
            </Table>
          </Row>

          <Row className='mb-9 mt-10'>
            <Col className='d-flex justify-content-end'>
              <button
                type='submit'
                id='kt_sign_in_submit'
                className='btn btn-primary'
                disabled={loading}
              // disabled={
              //     lstProductDetails.filter(
              //         (obj) => obj.product_id == '' || obj.quantity == '' || obj.rate == ''
              //     ).length
              //         ? true
              //         : !formik.isValid
              //             ? true
              //             : false
              // }
              >
                {!loading && <span className='indicator-label'>{id ? 'Edit' : 'Add'}</span>}
                {loading && (
                  <span className='indicator-progress' style={{ display: 'block' }}>
                    Please wait...
                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                  </span>
                )}
              </button>
              <Button
                type='button'
                value=''
                className='btn btn-sm fs-5 btn-light-primary '
                onClick={navigateQuotationListing}
              >
                Cancel{' '}
              </Button>
            </Col>
          </Row>

          <ToastContainer className='p-3' position={'top-end'}>
            <Toast
              show={sessionMessage.show}
              onClose={() => {
                setSessionMessage({
                  type: '',
                  message: '',
                  show: false,
                })
              }}
              bg='danger'
            >
              <Toast.Body className='text-white'>{sessionMessage.message}</Toast.Body>
            </Toast>
          </ToastContainer>

          <Toaster
            position='top-center'
            toastOptions={{
              duration: 3000,
              // role: 'status',
              // ariaLive: 'polite',
              style: {
                background: 'green',
                color: 'whitesmoke',
              },
              iconTheme: {
                primary: 'red',
                secondary: 'white',
              },
            }}
          />
        </form>
      )}
    </>
  )
}

export default AddEditQuotation
