import { useFormik } from 'formik'
import * as Yup from 'yup'
import { Col, Form, Row, Toast, ToastContainer } from 'react-bootstrap'
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { useEffect, useState } from 'react'
import Select from 'react-select'
import { checkFinalOfferPriceApi, checkMultipleOfferPriceApi, checkOfferPriceApi, locationListApi, productGradeListApi } from './_request'
import { checkDisplayValue } from '../../utils/utils'
import { KTCardBody, showToast } from '../../../_metronic/helpers'
import { OffersListLoading } from '../offer-management/offers-list/components/loading/OffersListLoading'
import { useIntl } from 'react-intl'
import { ToastType } from '../../../_metronic/helpers/crud-helper/ToastTypes'
import { getAllProduct } from '../inquiry-management/Inquiry-list/core/_requests'




const OfferWithPrice = () => {
  const [sessionMessage, setSessionMessage] = useState({
    type: '',
    message: '',
    show: false,
  })
  const [loading, setLoading] = useState(false);
  const [productNameList, setProductNameList] = useState<any>([])
  const [selectedProductNameList, setSelectedProductNameList] = useState<any>({})
  const [locationList, setLocationList] = useState<any>([])
  const[ sourceLocationList , setSourceLocationList] = useState<any>([])
  const [selectedLocationList, setSelectedLocationList] = useState<any>({ "label": "Ahmedabad", "value": "3cb71b1b-1b44-40d2-8dd7-c85d7b994549" })
  const [offerDetail, setOfferDetail] = useState<any>([])
  const [offerPrice, setOfferPrice] = useState<any>([])
  const [getCityData, setCityData] = useState<any>()
  const [sourceLocation1, setSourceLocation1] = useState();
  const [sourceLocation2, setSourceLocation2] = useState();
  const [sourceLocation3, setSourceLocation3] = useState();
  // const [sourceLocation, setSourceLocation] = useState({ value: '3cb71b1b-1b44-40d2-8dd7-c85d7b994549', label: 'Ahmedabad' });
  const [destinationLocation, setDestinationLocation] = useState({ value: '3cb71b1b-1b44-40d2-8dd7-c85d7b994549', label: 'Ahmedabad' });


const intl = useIntl()
const offerValidation = Yup.object().shape({
  // source_location_id: Yup.string().required('Source Location is required'),
  
  destination_location_id: Yup.string().required('Destination Location is required'),

  product_name_1: Yup.string(),
  quantity_1: Yup.string()
    .when('product_name_1', {
      is: (val:any) => val && val.trim() !== '',
      then: (schema) => schema.required('Quantity is required').min(1, 'Quantity must be greater than 0'),
      otherwise: (schema) => schema.notRequired(),
    }),

  product_name_2: Yup.string(),
  quantity_2: Yup.string()
    .when('product_name_2', {
      is: (val:any) => val && val.trim() !== '',
      then: (schema) => schema.required('Quantity is required').min(1, 'Quantity must be greater than 0'),
      otherwise: (schema) => schema.notRequired(),
    }),

  product_name_3: Yup.string(),
  quantity_3: Yup.string()
    .when('product_name_3', {
      is: (val:any) => val && val.trim() !== '',
      then: (schema) => schema.required('Quantity is required').min(1, 'Quantity must be greater than 0'),
      otherwise: (schema) => schema.notRequired(),
    }),
});

  const formik = useFormik({
    initialValues: {
        source_location_1:'',
        source_location_2:'',
        source_location_3:'',
        destination_location_id: '3cb71b1b-1b44-40d2-8dd7-c85d7b994549',
        product_name_1: '',
        quantity_1: '',
        product_name_2: '',
        quantity_2: '',
        product_name_3: '',
        quantity_3: ''
    },
    validationSchema: offerValidation,
    onSubmit: async (values: any) => {
      const sourceLocations = [values.source_location_1,values.source_location_2,values.source_location_3]
      const productNames = [
        { id: values.product_name_1, quantity: values.quantity_1 },
        { id: values.product_name_2, quantity: values.quantity_2 },
        { id: values.product_name_3, quantity: values.quantity_3 }
      ]
      
  let isValidProduct = false;
  let isValidSource = false;

  // Check if at least one valid product exists
  for (let i = 0; i < productNames.length; i++) {
    if (productNames[i].id && productNames[i].id.trim() !== "") {
      isValidProduct = true;
      break; // Exit loop early if valid product is found
    }
  }

  // Check if at least one valid source location exists
  for (let i = 0; i < sourceLocations.length; i++) {
    if (sourceLocations[i] && sourceLocations[i].trim() !== "") {
      isValidSource = true;
      break; // Exit loop early if valid source is found
    }
  }

  if (!isValidProduct || !isValidSource) {
    showToast({ 
      message: 'Please enter at least one valid Source Location and Product Name with Quantity.', 
      type: ToastType.ERROR, 
      autoClose: 2000 
    });
    return; // Stop API call
  }
     
      await checkMultipleOfferPriceApi(productNames, values.destination_location_id, sourceLocations).then(async (data: any) => {
        console.log("🚀 ~ awaitcheckMultipleOfferPriceApi ~ data:", data)
        if (data?.responseStatus == 200 && data?.responseData) {
          let offerData1 = data?.responseData ?? []
          console.log("🚀 ~ awaitcheckMultipleOfferPriceApi ~ offerData1:", offerData1)
          
          setOfferDetail(offerData1)
         
        } else {
         
          let message = data?.responseMessage ? data?.responseMessage : 'Something went wrong'
          setSessionMessage({ type: 'error', message: message, show: true })
        }
      }).catch((err: any) => {
        setOfferPrice([])
        setOfferDetail([])
        let message = err?.message
          ? err.message
          : err?.responseMessage
            ? err.responseMessage
            : ('Something went wrong' as any)
        setSessionMessage({ type: 'error', message: message, show: true })
      })
    },
  });


  useEffect(() => {
    if (sessionMessage.show) {
      setTimeout(() => {
        setSessionMessage({
          type: '',
          message: '',
          show: false,
        })
      }, 5000)
    }
  }, [sessionMessage.message])

  const getProductNameList = async (search: any) => {
    try {
      let dataProduct: any = await getAllProduct()
      
      if (dataProduct.responseStatus == 200 ) {
        
        let lstTmpProduct = dataProduct.responseData.map((obj: any) => {
          return { value: obj.id, label: obj.product_variation }
        })
        setProductNameList(lstTmpProduct);
      } else {
        let message = 'Something went wrong'
        setSessionMessage({ type: 'error', message: message, show: true })
      }
    } catch (err: any) {
      let message = err?.message
        ? err.message
        : err?.responseMessage
          ? err.responseMessage
          : ('Something went wrong' as any)
      setSessionMessage({ type: 'error', message: message, show: true })
    }
  }

  const getLocationList = async (search: any) => {
    try {
      let { responseStatus, responseData, responseMessage } = await locationListApi(search) as any
      if (responseStatus == 200 && responseData) {
        setLocationList(
          responseData.map((x: any) => {
            x.label = x.name
            x.value = x.id
            return x
          })
        )
        setSourceLocationList(
          responseData.map((x: any) => {
            x.label = x.name
            x.value = x.id   
            return x
          })
        )
      } else {
        let message = responseMessage ? responseMessage : 'Something went wrong'
        setSessionMessage({ type: 'error', message: message, show: true })
      }
    } catch (err: any) {
      let message = err?.message
        ? err.message
        : err?.responseMessage
          ? err.responseMessage
          : ('Something went wrong' as any)
      setSessionMessage({ type: 'error', message: message, show: true })
    }
  }

  
  
  useEffect(() => {
    getProductNameList('')
    getLocationList('')
  }, [])
  

  return (
    <>
      <div className="d-flex justify-content-between align-items-center">
      
      <div className="fw-bold fs-2">Multiple Offer With Price</div>
    </div>
      <div className='card card-body p-12 mt-10'>
        <Row className='mb-5'>
          <Col md={1}>
          </Col>
          <Col md={10}>
          <form
            className='form w-100'
            onSubmit={formik.handleSubmit}
            noValidate
            id='kt_login_signin_form'
          >
            <Row className='mb-5'>
              {/* Source Location
              <Col xl={6}>
                <Form.Group>
                  <Form.Label className='fw-bold required form-label'>Source Location</Form.Label>
                  <Select
                    options={locationList}
                    className='custom_select'
                    classNamePrefix='Select'
                    placeholder='Select Source Location'
                    value={sourceLocation}
                    onChange={(e: any) => {
                      formik.setFieldValue('source_location_id', e.value);
                      setSourceLocation(e);
                    }}
                  />
                </Form.Group>
              </Col> */}
              <Col xl={4}> </Col>
              {/* Destination Location */}
              <Col xl={4}>
                <Form.Group>
                  <Form.Label className='fw-bold required form-label'>Destination Location</Form.Label>
                  <Select
                    options={locationList}
                    className='custom_select'
                    classNamePrefix='Select'
                    placeholder='Select Destination Location'
                    value={destinationLocation}
                    onChange={(e: any) => {
                      formik.setFieldValue('destination_location_id', e.value);
                      setDestinationLocation(e);
                    }}
                  />
                </Form.Group>
              </Col>
              <Col xl={4}> </Col>
            </Row>
            <Row className='mb-5'>
                {/* Source Location 1 */}
                <Col xl={4}>
                  <Form.Group>
                    <Form.Label className='fw-bold  form-label'>Source Location 1</Form.Label>
                    <Select
                      options={sourceLocationList.filter((location : any) => location.value !== formik.values.source_location_2 && location.value !== formik.values.source_location_3)}
                      className='custom_select'
                      classNamePrefix='Select'
                      placeholder='Select Source Location 1'
                      value={sourceLocation1}
                      onChange={(e: any) => {
                        formik.setFieldValue('source_location_1', e.value);
                        setSourceLocation1(e);
                      }}
                    />
                  </Form.Group>
                </Col>

                {/* Source Location 2 */}
                <Col xl={4}>
                  <Form.Group>
                    <Form.Label className='fw-bold  form-label'>Source Location 2</Form.Label>
                    <Select
                      options={sourceLocationList.filter((location : any) => location.value !== formik.values.source_location_1 && location.value !== formik.values.source_location_3)}
                      className='custom_select'
                      classNamePrefix='Select'
                      placeholder='Select Source Location 2'
                      value={sourceLocation2}
                      onChange={(e: any) => {
                        formik.setFieldValue('source_location_2', e.value);
                        setSourceLocation2(e);
                      }}
                    />
                  </Form.Group>
                </Col>

                {/* Source Location 3 */}
                <Col xl={4}>
                  <Form.Group>
                    <Form.Label className='fw-bold  form-label'>Source Location 3</Form.Label>
                    <Select
                      options={sourceLocationList.filter((location : any) => location.value !== formik.values.source_location_2 && location.value !== formik.values.source_location_1)}
                      className='custom_select'
                      classNamePrefix='Select'
                      placeholder='Select Source Location 3'
                      value={sourceLocation3}
                      onChange={(e: any) => {
                        formik.setFieldValue('source_location_3', e.value);
                        setSourceLocation3(e);
                      }}
                    />
                  </Form.Group>
                </Col>
              </Row>
            {/* Product Groups & Quantities */}
            <Row className="mb-3">
              <Col xl={6}>
                <Form.Group>
                  <Form.Label className="fw-bold  form-label">Product Name 1</Form.Label>
                  <Select
                    options={productNameList.filter((grp: any) => grp.value !== formik.values.product_name_2 && grp.value !== formik.values.product_name_3)}
                    className="custom_select"
                    classNamePrefix="Select"
                    placeholder="Select Product Name 1"
                    value={productNameList.find((grp: any) => grp.value === formik.values.product_name_1)}
                    onChange={(e: any) => {
                      formik.setFieldValue("product_name_1", e.value);
                    }}
                  />
                  {formik.touched.product_name_1 && formik.errors.product_name_1 && (
                    <div className="text-danger">{formik?.errors?.product_name_1}</div>
                  )}
                </Form.Group>
              </Col>
              <Col xl={6}>
                <Form.Group>
                  <Form.Label className="fw-bold  form-label">Quantity 1</Form.Label>
                  <Form.Control
                    type="text"
                    className="form-control-solid"
                    placeholder="Enter Quantity 1"
                    value={formik.values.quantity_1}
                    onChange={(e: any) => {
                      const value = e.target.value.trimStart();
                      if (/^\d*$/.test(value) && Number(value) <= 100) {
                        formik.setFieldValue("quantity_1", value);
                      }
                    }}
                    // onChange={(e: any) => {
                    //   formik.setFieldValue("quantity_1", e.target.value.trimStart());
                    // }}
                  />
                  {formik.touched.quantity_1 && formik.errors.quantity_1 && (
                    <div className="text-danger">{formik?.errors?.quantity_1}</div>
                  )}
                </Form.Group>
              </Col>
            </Row>

            <Row className="mb-3">
              <Col xl={6}>
                <Form.Group>
                  <Form.Label className="fw-bold  form-label">Product Name 2</Form.Label>
                  <Select
                   options={productNameList.filter((grp: any) => grp.value !== formik.values.product_name_1 && grp.value !== formik.values.product_name_3)}
                    className="custom_select"
                    classNamePrefix="Select"
                    placeholder="Select Product Name 2"
                    value={productNameList.find((grp: any) => grp.value === formik.values.product_name_2)}
                    onChange={(e: any) => {
                      formik.setFieldValue("product_name_2", e.value);
                    }}
                  />
                  {formik?.touched?.product_name_2 && formik?.errors?.product_name_2 && (
                    <div className="text-danger">{formik?.errors?.product_name_2}</div>
                  )}
                </Form.Group>
              </Col>
              <Col xl={6}>
                <Form.Group>
                  <Form.Label className="fw-bold  form-label">Quantity 2</Form.Label>
                  <Form.Control
                    type="text"
                    className="form-control-solid"
                    placeholder="Enter Quantity 2"
                    value={formik.values.quantity_2}
                    onChange={(e: any) => {
                      const value = e.target.value.trimStart();
                      if (/^\d*$/.test(value) && Number(value) <= 100) {
                        formik.setFieldValue("quantity_2", value);
                      }
                    }}
                    // onChange={(e: any) => {
                    //   formik.setFieldValue("quantity_2", e.target.value.trimStart());
                    // }}
                  />
                  {formik?.touched?.quantity_2 && formik?.errors?.quantity_2 && (
                    <div className="text-danger">{formik?.errors?.quantity_2}</div>
                  )}
                </Form.Group>
              </Col>
            </Row>

            <Row className="mb-3">
              <Col xl={6}>
                <Form.Group>
                  <Form.Label className="fw-bold  form-label">Product Name 3</Form.Label>
                  <Select
                    options={productNameList.filter((grp: any) => grp.value !== formik.values.product_name_1 && grp.value !== formik.values.product_name_2)}
                    className="custom_select"
                    classNamePrefix="Select"
                    placeholder="Select Product Name 3"
                    value={productNameList.find((grp: any) => grp.value === formik.values.product_name_3)}
                    onChange={(e: any) => {
                      formik.setFieldValue("product_name_3", e.value);
                    }}
                  />
                  {formik?.touched?.product_name_3 && formik?.errors?.product_name_3 && (
                    <div className="text-danger">{formik?.errors?.product_name_3}</div>
                  )}
                </Form.Group>
              </Col>
              <Col xl={6}>
                <Form.Group>
                  <Form.Label className="fw-bold  form-label">Quantity 3</Form.Label>
                  <Form.Control
                    type="text"
                    className="form-control-solid"
                    placeholder="Enter Quantity 3"
                    value={formik.values.quantity_3}
                    onChange={(e: any) => {
                      const value = e.target.value.trimStart();
                      if (/^\d*$/.test(value) && Number(value) <= 100) {
                        formik.setFieldValue("quantity_3", value);
                      }
                    }}
                    // onChange={(e: any) => {
                    //   formik.setFieldValue("quantity_3", e.target.value.trimStart());
                    // }}
                  />
                  {formik?.touched?.quantity_3 && formik?.errors?.quantity_3 && (
                    <div className="text-danger">{formik?.errors?.quantity_3}</div>
                  )}
                </Form.Group>
              </Col>
            </Row>


            {/* Submit Button */}
            <Row>
              <Col md={4}></Col>
              <Col md={4}>
                <div className='d-grid mb-10'>
                  <button
                    type='submit'
                    id='kt_sign_in_submit'
                    className='btn btn-primary'
                    disabled={formik.isSubmitting}
                  >
                    {formik.isSubmitting ? 'Please Wait...' : 'Price'}
                  </button>
                </div>
              </Col>
              <Col md={4}></Col>
            </Row>

            {/* Toast Messages */}
            <ToastContainer className='p-3' position={'top-end'}>
              <Toast
                show={sessionMessage.show}
                onClose={() => {
                  setSessionMessage({ type: '', message: '', show: false });
                }}
                bg='danger'
              >
                <Toast.Body className='text-white'>{sessionMessage.message}</Toast.Body>
              </Toast>
            </ToastContainer>
           </form>
            {/* <KTCardBody className='py-4'> */}
              <div className='table-responsive'>
                <table
                  id='kt_table_users'
                  className='table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer offer-with-price-table'
                >
                  <thead>
                    <tr className='text-start text-muted fw-bolder fs-7 text-uppercase gs-0 bg-light'>
                      <th style={{ width: "10%" }}>Source</th>
                      <th style={{ width: "10%" }}>Product name 1</th>
                      <th style={{ width: "10%" }}>Product name 2</th>
                      <th style={{ width: "10%" }}>Product name 3</th>
                      <th style={{ width: "10%" }}>Price 1</th>
                      <th style={{ width: "10%" }}>Price 2</th>
                      <th style={{ width: "10%" }}>Price 3</th>
                      <th style={{ width: "10%" }}>Total Price</th>
                      <th style={{ width: "10%" }}>Offer 1</th>
                      <th style={{ width: "10%" }}>Offer 2</th>
                      <th style={{ width: "10%" }}>Offer 3</th>
                      
                    </tr>
                  </thead>
                  <tbody className='text-gray-600 fw-bold'>
                  
                  {offerDetail.length > 0 ? (
                    offerDetail.map((offer:any, index:any) => (
                      (offer.product_variation_1 !== "-" || 
                      offer.product_variation_2 !== "-" || 
                      offer.product_variation_3 !== "-") ? (
                        <tr key={index}>
                           <td>{checkDisplayValue(offer.source_location_name)}</td>
                          <td>{checkDisplayValue(offer.product_variation_1)}</td>
                          <td>{checkDisplayValue(offer.product_variation_2)}</td>
                          <td>{checkDisplayValue(offer.product_variation_3)}</td>
                          <td>{checkDisplayValue(offer.price_1)}</td>
                          <td>{checkDisplayValue(offer.price_2)}</td>
                          <td>{checkDisplayValue(offer.price_3)}</td>
                          
                          <td>  
                            {checkDisplayValue(
                              (
                                ((offer.price_1 !== "-" ? parseFloat(offer.price_1) || 0 : 0) * (parseInt(formik.values.quantity_1) || 0) ) +
                                ((offer.price_2 !== "-" ? parseFloat(offer.price_2) || 0 : 0) * (parseInt(formik.values.quantity_2) || 0) ) +
                                ((offer.price_3 !== "-" ? parseFloat(offer.price_3) || 0 : 0) * (parseInt(formik.values.quantity_3) || 0) )
                              ).toFixed(2) 
                            )}
                          </td>

                          <td>{checkDisplayValue(offer.offer_1)}</td>
                          <td>{checkDisplayValue(offer.offer_2)}</td>
                          <td>{checkDisplayValue(offer.offer_3)}</td>
                        </tr>
                      ) : <tr>
                        <td>-</td>
                        <td>-</td>
                        <td>-</td>
                        <td>-</td>
                        <td>-</td>
                        <td>-</td>
                        <td>-</td>
                        <td>-</td>
                        <td>-</td>
                        <td>-</td>
                        <td>-</td>
                    </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan={11}>
                        <div className='d-flex text-center w-100 align-content-center justify-content-center'>
                          No Offers Found
                        </div>
                      </td>
                    </tr>
                  )}
                  </tbody>
                </table>
    
              </div>
                       
              <Col xl={12}>
                <Col className='justify-content-between'>
                    <div className=' mb-3'>Term & Condition</div>
                    <div >
                    <div className='mb-3'>
                        Rate: for <strong>{(parseFloat(formik.values.quantity_1) || 0) + 
             (parseFloat(formik.values.quantity_2) || 0) + 
             (parseFloat(formik.values.quantity_3) || 0)} MT.</strong>
                    </div>
                    
                    <div className='mb-3'>
                        Supply: Delivered
                    </div>
                    
                    <div className='mb-3'>
                      Payment Terms: Pay on Delivery
                    </div>
                    
                    <div className='mb-3'>
                    Location:<strong>{locationList.find((x: any) => x.value === formik.values.destination_location_id)?.label ?? '-'}</strong> 
                    </div>
                    
                    <div className='mb-3'>
                        Subject to unsold
                    </div>
                    
                    <div>
                        To confirm order reply with <strong>confirm.</strong>
                    </div>
                    </div>
                </Col>
            </Col>
              {formik.isSubmitting && <OffersListLoading />}
            {/* </KTCardBody> */}
          </Col>
         
        </Row>

      </div>
    </>
  )
}
export default OfferWithPrice
