import React, {useEffect, useState} from 'react'
import {Button, Col, Form, Row, Spinner, Table, Toast, ToastContainer} from 'react-bootstrap'
import {Link, useNavigate, useParams} from 'react-router-dom'
import {KTSVG, toAbsoluteUrl} from '../../../_metronic/helpers'
import {PageLink, PageTitle} from '../../../_metronic/layout/core'
import {getQuotationByIdNew, sendEmailQuotation} from './quotatuins-list/core/_requests'
import {
  getAllProduct,
  getCGST,
  getIGST,
  getLocation,
  getSGST,
  quotationTC,
} from '../inquiry-management/Inquiry-list/core/_requests'
import {getAllStateList} from './quotatuins-list/core/_requests'
import Select from 'react-select'
import moment from 'moment/moment'
import jsPDF from 'jspdf'
import html2canvas from 'html2canvas'
import {
  checkDisplayValue,
  checkEmptyValue,
  getFormatPrice,
  getFormatedAddress,
  getTotalFormatPrice,
} from '../../utils/utils'
import {ToWords} from 'to-words'

const usersBreadcrumbs: Array<PageLink> = [
  {
    title: 'Buyer Management',
    path: '/buyer',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

const ViewQuotation = () => {
  const toWords = new ToWords()
  const navigate = useNavigate()
  const params = useParams()
  const id: string = params.id ? params.id : ''
  const [quotation, setQuotation] = useState<any>(null)
  const [billingAddress, setBillingAddress] = useState<any>(null)
  const [shippingAddress, setShippingAddress] = useState<any>(null)
  const [lstLocation, setLstLocation] = useState([])
  const [shipingDistrict, setShippingDistrict] = useState<any>(null)
  const [shipingState, setShippingState] = useState<any>(null)
  const [lstLocationState, setLstLocationState] = useState<any>(null)
  const [transportInfoState, setTransportInfoState] = useState<any>(null)
  const [tranfortStates, setTranfortState] = useState<any>(null)
  const [lstProduct, setLstProduct] = useState([])
  const [lstMasterProduct, setLstMasterProduct] = useState([])
  const [valueSGST, setValueSGST] = useState(0)
  const [valueIGST, setValueIGST] = useState(0)
  const [valueCGST, setValueCGST] = useState(0)
  const [isLoadData, setIsLoadData] = useState(false)
  const [isGujarat, setIsGujarat] = useState<any>('')
  const [tc, setTc] = useState<any>('')
  const [sessionMessage, setSessionMessage] = useState({
    type: '',
    message: '',
    show: false,
  })

  const [lstProductDetails, setLstProductDetails] = useState([
    {
      product_id: '',
      quantity: '',
      rate: '',
    },
  ])
  const [loadingGetData, setLoadingGetData] = useState(false)

  const navigateAddQuotation = () => {
    // 👇️ navigate to /
    navigate('/addquotation')
  }
  const navigateQuotationListing = () => {
    // 👇️ navigate to /
    navigate('/quotation-management/quotations')
  }

  useEffect(() => {
    getQuotation()
    console.log('call getQuotation')
  }, [])

  useEffect(() => {
    getDistrict()
  }, [quotation])

  const getDistrict = async () => {
    setIsLoadData(true)
    // District
    let dataLocation: any = await getLocation()
    let lstTmpLocation = dataLocation.responseData.map((obj: any) => {
      return {value: obj.id, label: obj.name}
    })

    setLstLocation(lstTmpLocation)
    // State
    let dataLocationState: any = await getAllStateList()
    let lstTmpLocationState = dataLocationState.responseData.map((obj: any) => {
      return {value: obj.id, label: obj.name}
    })

    setLstLocationState(lstTmpLocationState)
    setTranfortState(lstTmpLocationState)

    // Product
    let dataProduct: any = await getAllProduct()
    let lstTmpProduct = dataProduct.responseData.map((obj: any) => {
      return {value: obj.id, label: obj.product_variation}
    })
    setLstProduct(lstTmpProduct)
    setLstMasterProduct(dataProduct.responseData)

    // SGST
    let SGST: any = await getSGST()

    let tmpSGST = SGST.responseData.value
    setValueSGST(tmpSGST)

    // IGST
    let IGST: any = await getIGST()
    console.log(IGST, 'IGST')
    let tmpIGST = IGST.responseData.value
    setValueIGST(tmpIGST)

    // CGST
    let CGST: any = await getCGST()
    let tmpCGST = CGST.responseData.value
    setValueCGST(tmpCGST)
    setIsLoadData(false)
  }
  const getQuotation = async () => {
    setLoadingGetData(true)

    let aAndc: any = await quotationTC()
    setTc(aAndc?.responseData?.value)

    let user = await getQuotationByIdNew(id)
    console.log(user.responseData, 'getQuotationById user')
    let billingAddressDetail = user?.responseData?.billingAddressLocationInfo ?? ({} as any)
    setBillingAddress({
      address_line_1: billingAddressDetail?.address_line_1 ?? '',
      address_line_2: billingAddressDetail?.address_line_2 ?? '',
      district: billingAddressDetail?.addressLocationInfo?.name ?? '',
      city: billingAddressDetail?.city ?? '',
      state: billingAddressDetail?.addressLocationInfo?.stateInfo?.name ?? '',
      country: billingAddressDetail?.country ?? '',
      pincode: billingAddressDetail?.pincode ?? '',
    })

    let ahippingAddressDetail = user?.responseData?.shippingAddressLocationInfo ?? ({} as any)
    setShippingAddress({
      address_line_1: ahippingAddressDetail?.address_line_1 ?? '',
      address_line_2: ahippingAddressDetail?.address_line_2 ?? '',
      district: ahippingAddressDetail?.addressLocationInfo?.name ?? '',
      city: ahippingAddressDetail?.city ?? '',
      state: ahippingAddressDetail?.addressLocationInfo?.stateInfo?.name ?? '',
      country: ahippingAddressDetail?.country ?? '',
      pincode: ahippingAddressDetail?.pincode ?? '',
    })
    setQuotation(user.responseData)
    setIsGujarat(billingAddressDetail?.addressLocationInfo?.state_id ?? '')
    setLstProductDetails(user.responseData.quotationDetailsInfo)
    setLoadingGetData(false)
  }

  // District
  const selectedValueDistrict = (id: string) => {
    let selected = lstLocation.filter((obj: any) => obj.value == id)
    if (selected.length) {
      setShippingDistrict(selected[0])
      return selected[0]
    } else {
      return {}
    }
  }

  // State
  const selectedValueState = (id: string) => {
    let selected = lstLocationState.filter((obj: any) => obj.value == id)
    if (selected.length) {
      setShippingState(selected[0])
      return selected[0]
    } else {
      return {}
    }
  }
  const selectedValuetransportInfoState = (id: string) => {
    console.log(tranfortStates, id, 'tranfortStates,id')
    let selected = tranfortStates.filter((obj: any) => obj.value == id)
    if (selected.length) {
      setTransportInfoState(selected[0])
      return selected[0]
    } else {
      return {}
    }
  }

  // Product
  const selectedValueProduct = (id: string) => {
    let selected = lstProduct.filter((obj: any) => obj.value == id)
    if (selected.length) {
      console.log(selected[0], 'selected[0]000000000000')
      return selected[0]
    } else {
      return {}
    }
  }
  console.log(transportInfoState, 'transportInfoState=========')

  // Get master object
  const getMasterProductObject = (id: string) => {
    let selected = lstMasterProduct.filter((obj: any) => obj.id == id)
    if (selected.length) {
      return selected[0]
    } else {
      return {}
    }
  }

  const getTotalAmoutWithoutTex = () => {
    let total: any = 0

    lstProductDetails.map((obj) => {
      let tmp =
        parseFloat(obj.rate) && parseFloat(obj.quantity)
          ? parseFloat(obj.rate) * parseFloat(obj.quantity)
          : 0
      total = total + tmp * 1000
    })
    return total
  }

  const getSGSTValue = () => {
    let total: any = getTotalAmoutWithoutTex()
    let sGst: any = (total / 100) * valueSGST
    return sGst
  }

  const getCGSTValue = () => {
    console.log('getTotalAmoutWithoutTex() :: ', getTotalAmoutWithoutTex())
    console.log('valueCGST :: ', valueCGST)
    let total: any = getTotalAmoutWithoutTex()

    let cGst: any = (total / 100) * valueCGST
    console.log('cGst :: ', cGst, valueCGST)
    return cGst
  }

  const getIGSTValue = () => {
    let total: any = getTotalAmoutWithoutTex()
    let iGst: any = (total / 100) * valueIGST
    console.log(total, valueIGST, 'total,iGst')
    return iGst
  }

  const getTotalAmoutWithTex = () => {
    let total: any =
      getTotalAmoutWithoutTex() +
      (isGujarat !== '3749b049-6434-4e8b-8358-478251a33f8d'
        ? parseFloat(getIGSTValue())
        : parseFloat(getSGSTValue()) + parseFloat(getCGSTValue()))
    // let total: any = getTotalAmoutWithoutTex() + getSGSTValue() + getCGSTValue() + getIGSTValue()
    return parseFloat(total)
  }

  const dateForPicker = (dateString: any) => {
    return moment(new Date(dateString)).format('DD-MM-YYYY')
  }
  console.log(quotation, 'quotation')

  const Print = () => {
    //console.log('print');

    // var print_div: any = document.getElementById('printablediv')
    // var print_area: any = window.open()
    // print_area.document.write(print_div.innerHTML)
    // print_area.document.close()
    // print_area.focus()
    // print_area.print()
    // print_area.close()

    let printContentsRoot: any = document.getElementById('printablediv')
    let printContents = printContentsRoot.innerHTML
    let originalContents = document.body.innerHTML
    document.body.innerHTML = printContents
    window.print()
    document.body.innerHTML = originalContents
    window.location.reload()
  }

  const exportPdf = () => {
    // html2canvas(document.querySelector('#printablediv') as any).then((canvas: any) => {
    //   const imgData = canvas.toDataURL('image/png')
    //   const pdf: any = new jsPDF()
    //   var width = pdf.internal.pageSize.getWidth()
    //   var height = pdf.internal.pageSize.getHeight()

    //   pdf.addImage(imgData, 'PNG', 0, 0, width, height)
    //   pdf.save('download.pdf')
    // })
    html2canvas(document.querySelector('#printablediv') as any).then(function (canvas: any) {
      var imgWidth = 210
      var pageHeight = 290
      var imgHeight = (canvas.height * imgWidth) / canvas.width
      var heightLeft = imgHeight

      var doc = new jsPDF('p', 'mm')
      var position = 0
      var pageData = canvas.toDataURL('image/jpeg', 1.0)
      var imgData = encodeURIComponent(pageData)
      doc.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight)
      doc.setLineWidth(5)
      doc.setDrawColor(255, 255, 255)
      doc.rect(0, 0, 210, 295)
      heightLeft -= pageHeight

      while (heightLeft >= 0) {
        position = heightLeft - imgHeight
        doc.addPage()
        doc.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight)
        doc.setLineWidth(5)
        doc.setDrawColor(255, 255, 255)
        doc.rect(0, 0, 210, 295)
        heightLeft -= pageHeight
      }
      doc.save(`PolyMart_${quotation?.quotation_number ?? 'quotation_file'}.pdf`)
    })
  }

  const newPrintPdf = () => {
    // html2canvas(document.querySelector('#printablediv') as any).then((canvas: any) => {
    //   const imgData = canvas.toDataURL('image/png')
    //   const pdf: any = new jsPDF()
    //   var width = pdf.internal.pageSize.getWidth()
    //   var height = pdf.internal.pageSize.getHeight()

    //   pdf.addImage(imgData, 'PNG', 0, 0, width, height)
    //   pdf.save('download.pdf')
    // })
    html2canvas(document.querySelector('#printablediv') as any).then(function (canvas: any) {
      var imgWidth = 210
      var pageHeight = 290
      var imgHeight = (canvas.height * imgWidth) / canvas.width
      var heightLeft = imgHeight

      var doc = new jsPDF('p', 'mm')
      var position = 0
      var pageData = canvas.toDataURL('image/jpeg', 1.0)
      var imgData = encodeURIComponent(pageData)
      doc.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight)
      doc.setLineWidth(5)
      doc.setDrawColor(255, 255, 255)
      doc.rect(0, 0, 210, 295)
      heightLeft -= pageHeight

      while (heightLeft >= 0) {
        position = heightLeft - imgHeight
        doc.addPage()
        doc.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight)
        doc.setLineWidth(5)
        doc.setDrawColor(255, 255, 255)
        doc.rect(0, 0, 210, 295)
        heightLeft -= pageHeight
      }
      doc.autoPrint()
      //This is a key for printing
      doc.output('dataurlnewwindow')
      // doc.save(`PolyMart_${quotation?.quotation_number ?? "quotation_file"}.pdf`)
    })
  }

  console.log(shippingAddress, 'shipingStateshipingState')

  const sendEmail = async () => {
    try {
      await sendEmailQuotation(id)
        .then((data: any) => {
          if (data.responseStatus == 200) {
            setSessionMessage({type: 'success', message: data.responseMessage, show: true})
          } else {
            setSessionMessage({
              type: 'danger',
              message: data.responseMessage ?? 'Something Went Wrong',
              show: true,
            })
          }
        })
        .catch((err: any) => {
          setSessionMessage({
            type: 'danger',
            message: err.message ?? 'Something Went Wrong',
            show: true,
          })
        })
    } catch (err: any) {
      setSessionMessage({
        type: 'danger',
        message: err?.message ?? 'Something Went Wrong',
        show: true,
      })
      console.log(err)
    }
  }

  useEffect(() => {
    setTimeout(() => {
      setSessionMessage({type: '', message: '', show: false})
    }, 3000)
  }, [sessionMessage.show])

  return (
    <>
      <PageTitle> Quotation Details </PageTitle>
      {(quotation && isLoadData) || loadingGetData ? (
        <div
          style={{
            height: '550px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Spinner animation='border' />
        </div>
      ) : (
        <>
          <Col className='position-absolute me-5 end-0 tp-btn-mn quote-btn'>
            <Button
              type='button'
              value=''
              className='btn btn-sm fs-5 btn-light-danger print-icon me-5'
              onClick={() => {
                // Print()
                newPrintPdf()
                // window.print()
              }}
            >
              <span className=''>
                {' '}
                <i className='fa-solid fa-print fs-5 '></i>{' '}
              </span>{' '}
              Print{' '}
            </Button>

            <Button
              type='button'
              value=''
              className='btn btn-light-success  dwld-icon me-3'
              onClick={() => {
                exportPdf()
                // window.print()
              }}
            >
              <span className='svg-icon svg-icon-2 text-center '>
                <KTSVG
                  path='/media/icons/duotune/files/fil018.svg'
                  className='svg-icon-muted me-0'
                />
              </span>
              Download PDF
            </Button>

            <Button
              type='button'
              value=''
              className='btn btn-sm fs-5 btn-light-warning  wtsp-icon me-5'
              onClick={() =>
                window.open(
                  `https://wa.me/${quotation?.firmBuyerInfo?.whatsapp_number ?? 'send'}?text=Dear ${
                    quotation?.contact_person_name ? quotation?.contact_person_name : 'Customer'
                  } your quotation ${
                    quotation?.quotation_number ? quotation?.quotation_number : ''
                  } is created please contact us.`
                )
              }
            >
              <span className='svg-icon svg-icon-2 text-center'>
                {' '}
                <i className='fa-brands fa-whatsapp fs-3'></i>{' '}
              </span>{' '}
              Whatsapp{' '}
            </Button>
            {/* <a href={`mailto:${quotation?.buyerSellerInfo?.email}`}> */}
            <a
              href='#'
              onClick={(e: any) => {
                e.preventDefault()
              }}
            >
              <Button
                type='button'
                onClick={(e: any) => {
                  sendEmail()
                }}
                className='btn btn-sm fs-5  btn-light-info email-icon me-5'
              >
                <span className='svg-icon svg-icon-2 text-center'>
                  {' '}
                  <KTSVG
                    path='/media/icons/duotune/communication/com002.svg'
                    className='svg-icon-muted me-0 '
                  />{' '}
                </span>{' '}
                Send Email{' '}
              </Button>
            </a>
            <button
              type='button'
              onClick={navigateQuotationListing}
              value=''
              className='btn btn-sm fs-5 me-5 btn btn-primary'
            >
              Back
            </button>
          </Col>

          <Col className='card card-body backgroung-transparent   p-12  mt-10   p-12'>
            <div className='menu menu-column ' data-kt-menu='true'>
              <div
                className='d-flex flex-column bgi-no-repeat rounded-top'
                style={{
                  backgroundImage: `url('{toAbsoluteUrl('/media/misc/menu-header-bg.jpg')}')`,
                }}
              >
                <ul className='nav nav-line-tabs border-0 nav-stretch fw-bold  px-0'>
                  {/* <li className='nav-item nav-link text-dark opacity-75 fs-3 px-5 mb-8 opacity-state-100 pb-10 '>
                          Quotation Details
                      </li> */}

                  {/* <li className='nav-item'>
                        <a
                          className='nav-link text-dark opacity-75 fs-4 opacity-state-100 pb-4'
                          data-bs-toggle='tab'
                          href='#kt_topbar_notifications_2'
                        >
                          Quotation Log
                        </a>
                      </li> */}

                  {/* <li className='nav-item'>
                      <a
                        className='nav-link text-white opacity-75 opacity-state-100 pb-4'
                        data-bs-toggle='tab'
                        href='#kt_topbar_notifications_3'
                      >
                        Logs
                      </a>
                    </li> */}
                </ul>
              </div>

              <div className='tab-content' id='printablediv'>
                <div
                  className='tab-pane show active fade sales-view-pdng'
                  // id='kt_topbar_notifications_1'
                  role='tabpanel'
                  id='printablediv'
                >
                  <Row className='d-flex flex-column justify-content-center flex-lg-row mb-1'>
                    <Col xl={12} className='ms-auto me-auto mt-5 '>
                      <Table className='quot-table quote-upper-detail mb-0' hover>
                        <tbody>
                          <tr>
                            <td>
                              <Row
                                style={{borderBottom: '1.5px solid #000'}}
                                className='flex-nowrap mt-5 pb-5 d-flex justify-content-center'
                              >
                                <Col className='border-0'>
                                  <div className='d-table text-center'>
                                    <img
                                      style={{margin: '0 auto'}}
                                      className='h-60px logo d-block'
                                      src={toAbsoluteUrl('/media/logos/polymart-icon.svg')}
                                    />
                                    <img
                                      className='h-60px logo'
                                      src={toAbsoluteUrl('/media/logos/polymart-logo-blue.png')}
                                    />
                                  </div>
                                </Col>
                                <Col className='text-center border-0 justify-content-center'>
                                  <p className='fs-1 fw-bold mb-0 m-0'>PolyMart Private Limited</p>
                                  <p className='fs-4 fw-semibold text-dark mb-0 m-0'>
                                    515, The Capital 2, Science City Road,<br />
                                    Ahmedabad, Gujarat – 380060, India. <br />
                                    {/* Email ID: info@polymart.in, <br />  Phone: 6357111121, <br /> */}
                                    <b>GSTIN</b> :24AANCP3559K1ZX
                                  </p>
                                </Col>
                                <Col className='text-center justify-content-center d-flex'>
                                  <h2 style={{textAlign: 'left'}} className='purchase-text'>
                                    {' '}
                                    Sales Order
                                  </h2>
                                </Col>
                              </Row>
                            </td>
                          </tr>
                        </tbody>
                      </Table>
                      <Table className='quot-table quote-upper-detail mb-0' hover>
                        <tbody>
                          <tr>
                            <td className='ps-0 pe-0' style={{borderBottom: '0 !important'}}>
                              <Row className='flex-nowrap w-100'>
                                <Col md={4} style={{padding: '0 0px', marginRight: '5px'}}>
                                  <span
                                    style={{
                                      width: '130px',
                                      display: 'inline-block',
                                      fontWeight: 'bold',
                                      fontSize: '18px',
                                    }}
                                  >
                                    Bill To:
                                  </span>
                                  <br></br>
                                  <span className='d-block fw-bold'>
                                    {' '}
                                    {quotation?.buyerSellerInfo?.firm_name}{' '}
                                  </span>
                                  <span className='text-dark fw-semibold fs-4'>
                                    {' '}
                                    {getFormatedAddress(billingAddress)}
                                    {/* <span> {billingAddress?.district} </span> */}
                                    {/* <br /> */}
                                  </span>
                                  <p>
                                    <span
                                      className='quote-label'
                                      style={{width: '100px', fontWeight: 'bold', fontSize: '18px'}}
                                    >
                                      State:{' '}
                                    </span>
                                    <span className='text-dark fw-semibold fs-4'>
                                      {' '}
                                      {
                                        quotation?.billingAddressLocationInfo?.addressLocationInfo
                                          ?.stateInfo?.name
                                      }{' '}
                                    </span>
                                  </p>
                                  <p>
                                    <span
                                      className='quote-label'
                                      style={{width: '100px', fontWeight: 'bold', fontSize: '18px'}}
                                    >
                                      GSTIN No:{' '}
                                    </span>
                                    <span className='text-dark fw-semibold fs-4'>
                                      {' '}
                                      {quotation?.billingAddressLocationInfo?.gst}
                                    </span>
                                  </p>
                                </Col>
                                <Col md={3} style={{padding: '0 30px'}}>
                                  <span
                                    style={{
                                      width: '130px',
                                      display: 'inline-block',
                                      fontWeight: 'bold',
                                      fontSize: '18px',
                                    }}
                                  >
                                    Ship To:
                                  </span>
                                  <br></br>
                                  <span className='d-block fw-bold'>
                                    {' '}
                                    {quotation?.buyerSellerInfo?.firm_name
                                      ? quotation?.buyerSellerInfo?.firm_name
                                      : '-'}{' '}
                                  </span>
                                  <span>{getFormatedAddress(shippingAddress)}</span>
                                  {/* <span>
                                    {shippingAddress &&
                                      shippingAddress?.state &&
                                      `${shippingAddress?.state}, `}
                                  </span>
                                  <span>
                                    {' '}
                                    {shippingAddress &&
                                      shippingAddress?.district &&
                                      `${shippingAddress?.district}, `}
                                  </span>
                                  <span>
                                    {' '}
                                    {shippingAddress &&
                                      shippingAddress?.district &&
                                      `${shippingAddress?.district}, `}
                                  </span> */}
                                  <p>
                                    <span
                                      className='quote-label fw-bold'
                                      style={{width: '100px', fontSize: '18px'}}
                                    >
                                      State:{' '}
                                    </span>
                                    <span className='text-dark fw-semibold fs-4'>
                                      {shippingAddress &&
                                        shippingAddress?.state &&
                                        `${shippingAddress?.state}, `}
                                    </span>
                                  </p>
                                </Col>
                                <Col md={5} style={{padding: '0 0px 0 5px'}}>
                                  <div>
                                    <span
                                      style={{
                                        display: 'inline-block',
                                        fontWeight: 'bold',
                                        fontSize: '18px',
                                      }}
                                    >
                                      Sales Quotation No.:{' '}
                                    </span>
                                    <span className='text-dark fw-semibold fs-4'>
                                      {' '}
                                      {quotation?.quotation_number
                                        ? quotation?.quotation_number
                                        : '-'}{' '}
                                    </span>
                                  </div>
                                  <div>
                                    <span
                                      style={{
                                        display: 'inline-block',
                                        fontWeight: 'bold',
                                        fontSize: '18px',
                                      }}
                                    >
                                      Sales Quotation Date:{' '}
                                    </span>
                                    <span className='text-dark fw-semibold fs-4'>
                                      {' '}
                                      {quotation?.date ? dateForPicker(quotation?.date) : '-'}{' '}
                                    </span>
                                  </div>
                                  <div>
                                    <span
                                      style={{
                                        width: '150px',
                                        display: 'inline-block',
                                        fontWeight: 'bold',
                                        fontSize: '18px',
                                      }}
                                    >
                                      Reference :{' '}
                                    </span>
                                    <span className='text-dark fw-semibold fs-4'>
                                      {' '}
                                      {checkDisplayValue(quotation?.reference)}{' '}
                                    </span>
                                  </div>
                                  <div>
                                    <span
                                      style={{
                                        width: '150px',
                                        display: 'inline-block',
                                        fontWeight: 'bold',
                                        fontSize: '18px',
                                      }}
                                    >
                                      {'Payment Terms :'}
                                    </span>
                                    <span className='text-dark fw-semibold fs-4'>
                                    {quotation?.payment_terms == true 
                                      ? quotation?.payment_terms_note
                                      : quotation?.payment_terms == "3"
                                      ? "Advance Payment"
                                      : "Pay on Delivery"
                                    }

                                    </span>
                                  </div>
                                  <div>
                                    <span
                                      style={{
                                        width: '150px',
                                        display: 'inline-block',
                                        fontWeight: 'bold',
                                        fontSize: '18px',
                                      }}
                                    >
                                      Mode Of Dispatch:{' '}
                                    </span>
                                    <span className='text-dark fw-semibold fs-4'>
                                      {quotation?.transportInfo?.firm_name}{' '}
                                    </span>
                                  </div>
                                  <div>
                                    <span
                                      style={{
                                        width: '150px',
                                        display: 'inline-block',
                                        fontWeight: 'bold',
                                        fontSize: '18px',
                                      }}
                                    >
                                      Freight Terms:{' '}
                                    </span>
                                    <span className='text-dark fw-semibold fs-4'> Paid </span>
                                  </div>
                                </Col>
                              </Row>
                            </td>
                          </tr>
                        </tbody>
                      </Table>

                      <Table className='quot-table quote-upper-detail mb-0'>
                        <tbody>
                          <tr className='d-none'>
                            <td colSpan={4}>
                              <p>
                                {' '}
                                <span className='quote-label'>Ref. No.:</span>
                                <span className='fw-bold fs-5'>
                                  {' '}
                                  {checkDisplayValue(quotation?.reference)}{' '}
                                </span>{' '}
                              </p>
                              {/* <p className="text-left mb-3 mt-3"> <span className="badge fs-5 badge-primary py-2 px-4" data-kt-menu-dismiss="true">Confirmed Send - PO</span> </p> */}
                              {/* <p>
                                    {' '}
                                    <span className='quote-label'>Valid Until:</span>
                                    <span className='fw-bold'> 20/09/21 </span>{' '}
                                  </p> */}
                              <p>
                                <span className='quote-label'>Payment Terms: </span>{' '}
                                <span className='fw-bold'> - </span>{' '}
                              </p>
                              <p>
                                <span className='quote-label'>Mode Of Dispatch: </span>{' '}
                                <span className='fw-bold'>
                                  {quotation?.transportInfo?.firm_name}{' '}
                                </span>{' '}
                              </p>
                              <p className='mb-4'>
                                <span className='quote-label'>Freight Terms: </span>
                                <span className='fw-bold'> Paid </span>{' '}
                              </p>
                            </td>
                            <td colSpan={2}>
                              {/* <h4 className='border-bottom pb-2'>Details of Customer (Buyer)</h4> */}
                              <p>
                                <span className='quote-label'>Sales Quotation No.: </span>
                                <span className='fw-bold fs-5'>
                                  {' '}
                                  {quotation?.quotation_number
                                    ? quotation?.quotation_number
                                    : '-'}{' '}
                                </span>{' '}
                              </p>
                              {/* <p className="text-left mb-3 mt-3"> <span className="badge fs-5 badge-primary py-2 px-4" data-kt-menu-dismiss="true">Confirmed Send - PO</span> </p> */}
                              <p>
                                <span className='quote-label'>Sales Quotation Date:</span>{' '}
                                <span className='fw-bold'>
                                  {' '}
                                  {quotation?.date ? dateForPicker(quotation?.date) : '-'}{' '}
                                </span>{' '}
                              </p>
                              <p>
                                <span className='quote-label'>State: </span>{' '}
                                <span className='fw-bold'>
                                  {/* {billingAddress?.state} */}
                                  Gujarat
                                  {/* {quotation &&
                                  quotation?.buyerSellerInfo?.billingAddressInfo?.stateInfo
                                    ? quotation?.buyerSellerInfo?.billingAddressInfo?.stateInfo
                                        ?.name
                                    : '-'} */}
                                </span>
                              </p>
                              <p>
                                <span className='quote-label'>District: </span>{' '}
                                <span className='fw-bold'>
                                  {/* {quotation && quotation?.buyerSellerInfo?.billingAddressInfo?.name
                                    ? quotation?.buyerSellerInfo?.billingAddressInfo?.name
                                    : '-'} */}
                                  Ahmedabad
                                </span>
                              </p>
                              <p className='border-bottom border-dark pb-2'>
                                <span className='quote-label'>Place of Supply: </span>{' '}
                                <span className='fw-bold'> Ahmedabad </span>{' '}
                              </p>
                              <p className='pt-2'>
                                <span className='quote-label'>Contact Person: </span>{' '}
                                <span className='fw-bold'>
                                  {' '}
                                  {quotation?.contact_person_name
                                    ? quotation?.contact_person_name
                                    : '-'}{' '}
                                </span>{' '}
                              </p>
                              <p>
                                <span className='quote-label'>Phone no:</span>{' '}
                                <span className='fw-bold'>
                                  {' '}
                                  {quotation?.buyerSellerInfo?.person_1 == quotation?.buyer_name
                                    ? quotation?.buyerSellerInfo?.whatsapp_number
                                    : quotation?.buyerSellerInfo?.person_2 == quotation?.buyer_name
                                    ? quotation?.buyerSellerInfo?.whatsapp_number_2
                                    : '-'}{' '}
                                </span>{' '}
                              </p>
                              <p>
                                <span className='quote-label'>Email: </span>{' '}
                                <span className='fw-bold'>
                                  {' '}
                                  {quotation?.buyerSellerInfo?.person_1 == quotation?.buyer_name
                                    ? quotation?.buyerSellerInfo?.email
                                    : quotation?.buyerSellerInfo?.person_2 == quotation?.buyer_name
                                    ? quotation?.buyerSellerInfo?.email_2
                                    : '-'}{' '}
                                  {/* {quotation?.buyerSellerInfo?.email
                                    ? quotation?.buyerSellerInfo?.email
                                    : '-'}{' '} */}
                                </span>{' '}
                              </p>
                            </td>
                          </tr>
                          <tr className='d-none'>
                            <td colSpan={4}>
                              <h4 className='border-bottom pb-2'>Details of Customer (Buyer)</h4>
                              <p>
                                <span className='quote-label2'>Name: </span>{' '}
                                <span className='fw-bold'>
                                  {' '}
                                  {quotation?.buyerSellerInfo?.name}{' '}
                                </span>{' '}
                              </p>
                              <p className='d-flex'>
                                <span className='quote-label2'>Address: </span>
                                <span className='fw-bold '>
                                  {' '}
                                  {/*{billingAddress && billingAddress ? billingAddress : '' }*/}
                                  {billingAddress?.address_line_1}
                                  {/* <br /> */}
                                  {checkEmptyValue(billingAddress?.address_line_2)
                                    ? ', ' + billingAddress?.address_line_2
                                    : ''}
                                  {/* <br /> */}
                                </span>{' '}
                              </p>
                              <p>
                                <span className='quote-label2'>State: </span>{' '}
                                <span className='fw-bold'> {billingAddress?.state} </span>{' '}
                              </p>
                              <p>
                                <span className='quote-label2'>District: </span>{' '}
                                <span className='fw-bold'> {billingAddress?.district} </span>{' '}
                              </p>
                              <p>
                                <span className='quote-label2'>GSTIN No: </span>{' '}
                                <span className='fw-bold'>
                                  {quotation?.buyerSellerInfo?.GST_number}
                                </span>{' '}
                              </p>
                            </td>
                            <td colSpan={2}>
                              <h4 className='border-bottom pb-2'>Details of Customer (Ship to)</h4>
                              <p>
                                <span className='quote-label2'>Name: </span>{' '}
                                <span className='fw-bold'>
                                  {' '}
                                  {quotation?.buyerSellerInfo?.name
                                    ? quotation?.buyerSellerInfo?.name
                                    : '-'}{' '}
                                </span>{' '}
                              </p>
                              <p className='d-flex'>
                                <span className='quote-label2'>Address:</span>{' '}
                                <span className='fw-bold '>
                                  {shippingAddress &&
                                    shippingAddress?.address_line_1 &&
                                    `${shippingAddress?.address_line_1},`}
                                  {/* <br /> */}
                                  {shippingAddress &&
                                    shippingAddress?.address_line_2 &&
                                    `${shippingAddress?.address_line_2},`}
                                  {/* <br /> */}
                                  {shippingAddress &&
                                    shippingAddress?.city &&
                                    `${shippingAddress?.city}`}
                                  {/* <br /> */}
                                  {shipingDistrict && shipingDistrict?.label
                                    ? shipingDistrict?.label
                                    : ''}
                                </span>
                              </p>
                              <p>
                                <span className='quote-label2'>State: </span>{' '}
                                <span className='fw-bold'>
                                  {shippingAddress &&
                                    shippingAddress?.state &&
                                    `${shippingAddress?.state},`}
                                </span>{' '}
                              </p>
                              <p>
                                <span className='quote-label2'>District: </span>{' '}
                                <span className='fw-bold'>
                                  {' '}
                                  {shippingAddress &&
                                    shippingAddress?.district &&
                                    `${shippingAddress?.district},`}
                                </span>{' '}
                              </p>
                              <p>
                                <span className='quote-label2'>GSTIN No: </span>{' '}
                                <span className='fw-bold'>
                                  {' '}
                                  {quotation?.buyerSellerInfo?.GST_number
                                    ? quotation?.buyerSellerInfo?.GST_number
                                    : '-'}{' '}
                                </span>{' '}
                              </p>
                            </td>
                          </tr>
                          {/* <tr>
                  <td> Sr. No. </td>
                  <td> Discription </td>
                <td> Quantity </td>
                  <td> UOM </td>
                  <td> Del Date </td>
                  <td> &nbsp; </td>
                  <td> &nbsp; </td>
                  <td> &nbsp; </td>
                  <td> &nbsp; </td>
                  <td> &nbsp; </td>
                  <td> &nbsp; </td>
                  <td> &nbsp; </td>
                </tr> */}
                        </tbody>
                      </Table>
                    </Col>
                    <Col xl={12} className='ms-auto me-auto mt-0'>
                      <Table responsive className='quot-table text-center'>
                        <thead>
                          <tr>
                            <th style={{width: '12%'}}>Sr. No.</th>
                            <th>
                              Grade Name <span className='text-danger fs-5'> </span>
                            </th>
                            <th>
                              Product Name <span className='text-danger fs-5'> </span>
                            </th>
                            <th>
                              {' '}
                              HSN Code <span className='text-danger fs-5'> </span>{' '}
                            </th>
                            <th style={{width: '16%'}}>
                              Qty (MT) <span className='text-danger fs-5'> </span>{' '}
                            </th>
                            <th style={{width: '14%'}}>
                              Rate/Kg <span className='text-danger fs-5'> </span>{' '}
                            </th>
                            <th style={{width: '16%'}}>Amount (&#8377;)</th>
                          </tr>
                        </thead>
                        <tbody>
                          {lstProductDetails.map((objProduct: any, index) => {
                            let objMain = getMasterProductObject(objProduct?.product_id) as any
                            return (
                              <tr id='row1'>
                                <td>{index + 1}</td>
                                <td>
                                  {checkDisplayValue(objProduct?.productInfo?.gradeGroupInfo?.name)}
                                </td>
                                <td>
                                  <Form.Group>
                                    {objProduct?.productInfo?.product_variation
                                      ? objProduct?.productInfo?.product_variation
                                      : '-'}
                                  </Form.Group>
                                </td>
                                <td>
                                  {objProduct?.productInfo?.hsn
                                    ? objProduct?.productInfo?.hsn
                                    : '-'}
                                </td>
                                <td>
                                  <Form.Group className=''>{objProduct?.quantity}</Form.Group>
                                </td>

                                <td>
                                  <Form.Group className=''>{objProduct?.rate}</Form.Group>
                                </td>
                                <td>
                                  <Form.Group className=''>
                                    {getFormatPrice(
                                      parseFloat(objProduct.rate) && parseFloat(objProduct.quantity)
                                        ? (
                                            parseFloat(objProduct.rate) *
                                            parseFloat(objProduct.quantity) *
                                            1000
                                          ).toFixed(2)
                                        : 0
                                    )}
                                  </Form.Group>
                                </td>
                              </tr>
                            )
                          })}

                          {/* <tr>
                            <td> Total </td>
                            <td> &nbsp; </td>
                            <td> &nbsp; </td>
                            <td> &nbsp; </td>
                            <td> &nbsp; </td>
                            <td> &nbsp; </td>
                          </tr> */}
                          <tr>
                            <td rowSpan={7} colSpan={5}>
                              {' '}
                              &nbsp;{' '}
                            </td>
                            <td style={{verticalAlign: 'middle'}}> Taxable Amount </td>
                            <td colSpan={2}>
                              {' '}
                              <Form.Group className=''>
                                {getFormatPrice(getTotalAmoutWithoutTex().toFixed(2))}
                              </Form.Group>{' '}
                            </td>
                          </tr>
                          {isGujarat !== '3749b049-6434-4e8b-8358-478251a33f8d' ? (
                            <tr>
                              <td style={{verticalAlign: 'middle'}}> IGST </td>
                              <td colSpan={2}>
                                {' '}
                                <Form.Group className=''>
                                  {/*<Form.Control*/}
                                  {/*    type='text'*/}
                                  {/*    className='required form-control-solid'*/}
                                  {/*    placeholder='IGST'*/}
                                  {/*    value={getIGSTValue()}*/}
                                  {/*/>*/}
                                  {getFormatPrice(getIGSTValue().toFixed(2))}
                                </Form.Group>{' '}
                              </td>
                            </tr>
                          ) : (
                            <>
                              <tr>
                                <td style={{verticalAlign: 'middle'}}> CGST </td>
                                <td colSpan={2}>
                                  {' '}
                                  <Form.Group className=''>
                                    {/*<Form.Control*/}
                                    {/*    type='text'*/}
                                    {/*    className='required form-control-solid'*/}
                                    {/*    placeholder='CGST'*/}
                                    {/*    value={getCGSTValue()}*/}
                                    {/*/>*/}
                                    {getFormatPrice(getCGSTValue().toFixed(2))}
                                  </Form.Group>{' '}
                                </td>
                              </tr>
                              <tr>
                                <td style={{verticalAlign: 'middle'}}> SGST </td>
                                <td colSpan={2}>
                                  {' '}
                                  <Form.Group className=''>
                                    {/*<Form.Control*/}
                                    {/*    type='text'*/}
                                    {/*    className='required form-control-solid'*/}
                                    {/*    placeholder='SGST'*/}
                                    {/*    value={getSGSTValue()}*/}
                                    {/*/>*/}
                                    {getFormatPrice(getSGSTValue().toFixed(2))}
                                  </Form.Group>{' '}
                                </td>
                              </tr>
                            </>
                          )}
                          <tr>
                            <td style={{verticalAlign: 'middle'}}> Total Amount (With Tax) </td>
                            <td colSpan={2}>
                              {' '}
                              <Form.Group className=''>
                                {/*<Form.Control*/}
                                {/*    type='text'*/}
                                {/*    className='required form-control-solid'*/}
                                {/*    placeholder='Total Amount'*/}
                                {/*    value={getTotalAmoutWithTex()}*/}
                                {/*/>*/}
                                {getTotalFormatPrice(getTotalAmoutWithTex())}
                              </Form.Group>{' '}
                            </td>
                          </tr>
                        </tbody>
                      </Table>
                    </Col>
                  </Row>
                  <Row className='mt-2'>
                    <Col style={{border: '0px !important'}}>
                      <h5 className='text-left fs-4 fw-bold'>
                        Amount in Words: Rs.{' '}
                        {toWords.convert(getTotalAmoutWithTex(), {currency: true})}
                      </h5>
                    </Col>
                  </Row>
                  <Row className='mt-10'>
                    <Col className='text-center'>
                      <img
                        style={{margin: '0px auto 0px'}}
                        className='logo d-block'
                        height={'100px'}
                        src={toAbsoluteUrl('/media/logos/polymart-icon-01.svg')}
                      />
                      <span
                        style={{
                          display: 'inline-block',
                          fontWeight: 'bold',
                          fontSize: '18px',
                          marginTop: '30px',
                        }}
                      >
                        Let's unite to achieve a sustainable hunger free India. Our Commmitment: 1
                        meal per ton, 1 tree per order
                      </span>
                    </Col>
                  </Row>
                  {/* <Row className='mb-5 mt-9'></Row> */}
                  <Row className='mb-5 mt-9'>
                    <Col xl={12} className='ms-auto me-auto mt-0'>
                      <Table responsive className=' quot-tablepo-trm-cdtn'>
                        <tr className='po-trm-cdtn'>
                          <td colSpan={6} className='border-transparent border-bottom-transparent'>
                            <h4 className='mb-2'> Terms &amp; Conditions </h4>
                            <div
                              className='fs-4 text-dark'
                              dangerouslySetInnerHTML={{
                                __html: tc.replace(/\\n|\\t/g, '').replaceAll('"', ''),
                              }}
                            />
                            {/* <ul>
                            <li className='mb-3 fs-6'>
                              Lorem Ipsum is simply dummy text of the printing and typesetting
                              industry. Lorem Ipsum has been the industry's standard dummy text ever
                              since the 1500s, when an unknown printer took a galle
                            </li>
                            <li className='mb-3 fs-6'>
                              Lorem Ipsum is simply dummy text of the printing and typesetting
                              industry. Lorem Ipsum has been the industry's standard dummy text ever
                              since the 1500s, when an unknown printer took a galle
                            </li>
                            <li className='mb-3 fs-6'>
                              Lorem Ipsum is simply dummy text of the printing and typesetting
                              industry. Lorem Ipsum has been the industry's standard dummy text ever
                              since the 1500s, when an unknown printer took a galle
                            </li>
                          </ul> */}
                          </td>
                        </tr>
                        {/* <tr className='po-trm-cdtn'>
                          <td
                            colSpan={6}
                            className='border-transparent border-bottom-transparent text-left'
                          >
                            <p className='mt-20 fs-6'>Authorised Signature</p>
                          </td>
                        </tr> */}

                        {/* <tr className='mt-10'>
                          <td colSpan={6} style={{ borderBottom: '1px solid #f5f5f5' }}></td>
                        </tr> */}
                        <tr className='mt-10'>
                          <td colSpan={6} className='text-center'>
                            <b>
                              This is a system generated sales Order, hence the signature is not
                              required.
                            </b>
                          </td>
                        </tr>
                        <div
                          style={{borderTop: '1.5px solid #000'}}
                          className='d-flex justify-content-center mt-3 mb-3'
                        >
                          <h5 className='mt-5'>
                            Email: info@polymart.in <br />
                            Phone: 6357111121 <br />
                          </h5>
                        </div>
                      </Table>
                    </Col>
                  </Row>
                </div>

                {/* <div className='tab-pane fade' id='kt_topbar_notifications_2' role='tabpanel'>
                      <Col className=' ms-auto me-auto flex-column flex-lg-row-auto py-8  '>
                        <Row className='d-flex justify-content-between'>
                          <Col>
                          <div className='timeline-item d-flex'>
                              <div className='timeline-icon symbol symbol-circle symbol-40px me-4'>
                                <div className='symbol-label bg-light'>
                                  <KTSVG
                                    path='/media/icons/duotune/communication/com003.svg'
                                    className='svg-icon-2 svg-icon-gray-500'
                                  />
                                </div>
                              </div>

                              <div className='timeline-content mb-10 mt-n1'>
                                <div className='pe-3 mb-5'>
                                  <div className='fs-5 fw-bolder  text-gray-600 text-hover-primary pt-2 mb-2'>
                                    <span className='text-dark fw-bold fs-5 mt-1 text-hover-primary'>
                                      Aiyub Panara{' '}
                                    </span>{' '}
                                    has Confirmed
                                  </div>
                                  <div className='d-flex align-items-center mt-1 fs-6'>
                                    <div className='text-muted me-2 fs-7'>
                                      15<sup>th</sup> Oct. 2021, at 2:23 PM
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div className='timeline-item d-flex'>
                              <div className='timeline-icon symbol symbol-circle symbol-40px me-4'>
                                <div className='symbol-label bg-light'>
                                  <KTSVG
                                    path='/media/icons/duotune/communication/com003.svg'
                                    className='svg-icon-2 svg-icon-gray-500'
                                  />
                                </div>
                              </div>

                              <div className='timeline-content mb-10 mt-n1'>
                                <div className='pe-3 mb-5'>
                                  <div className='fs-5 fw-bolder  text-gray-600 text-hover-primary pt-2 mb-2'>
                                    <span className='text-dark fw-bold fs-5 mt-1 text-hover-primary'>
                                      {' '}
                                      Dharmendra Kushwah{' '}
                                    </span>{' '}
                                    has Sent Quotation
                                  </div>

                                  <div className='d-flex align-items-center mt-1 fs-6'>
                                    <div className='text-muted me-2 fs-7'>
                                      Added on 10<sup>th</sup> Oct. 2021, at 4:23 PM
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>


                          </Col>
                        </Row>
                      </Col>
                    </div> */}
              </div>
            </div>
            <ToastContainer className='p-3 position-fixed' position={'top-center'}>
              <Toast show={sessionMessage.show} bg={sessionMessage.type}>
                <Toast.Body className='text-white'>{sessionMessage.message}</Toast.Body>
              </Toast>
            </ToastContainer>
          </Col>
        </>
      )}
    </>
  )
}

export default ViewQuotation
